import React, { useState, useEffect } from 'react';
import './reseau.css'; // Assure-toi que les styles pour l'accordéon sont également inclus dans ce fichier CSS

const Tohm = () => {
  // Déclare un état pour gérer l'ouverture/fermeture des panneaux
  const [isOpen, setIsOpen] = useState({
    guide: false,
    exploitant: false,
  });

  // Met à jour le titre de la page
  useEffect(() => {
    document.title = "Le réseau de transport Oise Halatte Mobilité (TOHM)";
  }, []);

  // Fonction pour gérer l'ouverture/fermeture des panneaux
  const toggleAccordion = (section) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
      <div className="reseau-container">
        <section className="reseau-intro">
          <h1>Le réseau de transport Oise Halatte Mobilité (TOHM)</h1>
          <p>
            La Communauté de communes des Pays d’Oise et d’Halatte (CCPOH) développe un réseau de transports urbains gratuit adapté aux besoins des quartiers et centré sur la gare de Pont-Saint-Maxence, en connexion avec les trains.
          </p>
          <p>
            Depuis le 24 janvier 2022, 3 nouveaux arrêts supplémentaires (Le poirier, La flaque, La queue du chat) ont été mis en place sur la ligne 2 desservant la Zone Industrielle Pont/Brenouille.
          </p>
          <img
            src="https://www.oise-mobilite.fr/sites/default/files/styles/editoriale_image_droite_gauche/public/2023-06/vehicule-tohm.jpg"
            alt="TOHM Bus"
            className="reseau-image"
          />
        </section>

        <section className="reseau-details">
          <h2>Le TOHM permet :</h2>
          <ul>
            <li>Un accès direct à la gare, quel que soit votre lieu de départ,</li>
            <li>De nombreuses correspondances avec le train et une correspondance garantie entre le train et le dernier départ de bus le soir, jusqu’à quinze minutes de retard du train,</li>
            <li>Des correspondances optimisées entre les lignes,</li>
            <li>Un accès au collège direct et sans correspondance, quel que soit le lieu de départ,</li>
            <li>Une desserte plus fine de la Zone Industrielle Pont/Brenouille,</li>
            <li>Une liaison directe et sans correspondance le dimanche entre le quartier des Terriers, Zone commerciale Pasteur et le Centre commercial Val d’Halatte.</li>
          </ul>
          <p>
            Le TOHM est en service de 5h10 à 20h30 en semaine et de 7h15 à 19h40 le samedi. Il ne fonctionne pas les jours fériés.
          </p>
        </section>

        {/* Ajout de l'accordéon ici */}
        <section className="reseau-accordion">
          <div className="accordion-item">
            <button onClick={() => toggleAccordion('guide')}>
              Guide et plan
            </button>
            {isOpen.guide && (
            <div className="accordion-content">
              <p>
                Téléchargez le plan du réseau TOHM pour avoir une vision d'ensemble des trajets et des arrêts.
              </p>
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2023-10/plan-tohm-oct-2023.pdf" download className="download-button">
                  Télécharger le plan du TOHM
                </a>
              </div>
            </div>
            )}
          </div>

          <div className="accordion-item">
            <button onClick={() => toggleAccordion('exploitant')}>
              Exploitant
            </button>
            {isOpen.exploitant && (
              <div className="accordion-content">
                <h4>KEOLIS OISE, EXPLOITANT DU RÉSEAU DE TRANSPORTS URBAINS MAXIPONTAINS</h4>
                <p>
                  L’exploitation du réseau TOHM a été confiée à la société Keolis Oise, filiale du groupe Keolis, pour une durée de quatre ans conformément aux termes du marché de transports publics en vigueur depuis le 1er janvier 2017.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Tohm;
