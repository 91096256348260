import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import Cookies from 'js-cookie';
import './menu.css'; // Assurez-vous que le fichier CSS est importé
import logoBlanc from './assets/logo_blanc.svg';

// Import React Icons
import { FaListUl } from 'react-icons/fa';
import { BiSolidTrafficCone } from 'react-icons/bi';
import { CgArrowsExchange } from 'react-icons/cg';
import { RiCloseLargeFill } from 'react-icons/ri';
import { FaEuroSign } from 'react-icons/fa';
import { IoMenu } from 'react-icons/io5'; // Import de l'icône de menu
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'; // Import des flèches
import { FaBus } from "react-icons/fa";

// Fonction pour calculer la luminosité de la couleur
const isColorDark = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 140; // Si la luminosité est inférieure à 140, c'est une couleur sombre
};

const Header = () => {
  const location = useLocation();
  const [alerts, setAlerts] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false); // Pour le menu latéral
  const [showSubmenu, setShowSubmenu] = useState(false); // Contrôler l'affichage du sous-menu Réseau

  useEffect(() => {
    const fetchAlerts = () => {
      fetch('/alertes.php')
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data)) {
            const activeAlerts = data.filter(alert => {
              const alertClosed = Cookies.get(`alertClosed_${alert.id}`);
              return !alertClosed && alert.etat === 1;
            });
            setAlerts(activeAlerts);
          } else {
            setAlerts([]);
          }
        })
        .catch(error => {
          console.error('Error fetching alerts:', error);
          setAlerts([]);
        });
    };

    fetchAlerts();
    const interval = setInterval(fetchAlerts, 10000);
    return () => clearInterval(interval);
  }, []);

  const closeAlert = (id) => {
    Cookies.set(`alertClosed_${id}`, 'true', { expires: 365 });
    setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
  };

  const getNavLinkClass = (path) => {
    if (path === '/') {
      return location.pathname === '/' || location.pathname.startsWith('/network/') ? 'nav-link active' : 'nav-link';
    }
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  // Fonction pour fermer l'Offcanvas lorsque l'utilisateur clique sur un lien
  const handleLinkClick = (link) => {
    // Si le lien cliqué ne fait pas partie du sous-menu Réseau, on ferme le sous-menu
    if (link !== '/reseau/tohm' && link !== '/reseau/axo' && link !== '/reseau/tic' && link !== '/reseau/duc' && link !== '/reseau/corolis') {
      setShowSubmenu(false); // Fermer le sous-menu
    }
    setShowOffcanvas(false); // Fermer le Offcanvas dans tous les cas
  };

  // Fonction pour gérer le sous-menu Réseau
  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu); // Inverser l'état d'affichage du sous-menu
  };

  return (
    <>
      {alerts.map((alert, index) => {
        const backgroundColor = alert.color || '#d40000';
        const isDark = isColorDark(backgroundColor);
        const textColor = isDark ? 'white' : 'black';
        return (
          <div
            key={alert.id}
            className={`alert-bar ${alerts.length > 1 && index < alerts.length - 1 ? 'with-separator' : ''}`}
            style={{ backgroundColor, color: textColor }} // Appliquer le style de couleur de texte dynamiquement
          >
            <span className="alert-title">{alert.titre}</span>
            <span className="alert-message">{alert.message}</span>
            {alert.cookie === 1 && (
              <RiCloseLargeFill
                className="alert-close"
                onClick={() => closeAlert(alert.id)}
                style={{ color: textColor }} // Appliquer la couleur de l'icône de fermeture
              />
            )}
          </div>
        );
      })}

      {/* Navbar */}
      <Navbar className="navbar-custom" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={logoBlanc}
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          {/* Icône de menu personnalisée */}
          <IoMenu
            className="menu-icon"
            size={35}
            onClick={() => setShowOffcanvas(true)} // Ouvre le menu Offcanvas quand on clique
          />
        </Container>
      </Navbar>

      {/* Menu latéral Offcanvas */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
        <Offcanvas.Header closeButton>
          {/* Remplace le texte "Menu" par l'image venant de localhost/image/minhead.svg */}
          <Offcanvas.Title>
            <img src="/image/minhead.png" alt="Min Head" style={{ height: '50px' }} /> {/* Taille ajustée */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
   

<Nav.Link as={Link} to="/change-network" className={getNavLinkClass('/change-network')} onClick={() => handleLinkClick('/change-network')}>
  <CgArrowsExchange className="icon" /> Changer de réseau
</Nav.Link>
       

            <Nav.Link as={Link} to="/" className={getNavLinkClass('/')} onClick={() => handleLinkClick('/')}>
              <FaListUl className="icon" /> Les lignes
            </Nav.Link>
            <Nav.Link as={Link} to="/infotrafic" className={getNavLinkClass('/infotrafic')} onClick={() => handleLinkClick('/infotrafic')}>
              <BiSolidTrafficCone className="icon" /> Infos Trafic
            </Nav.Link>
            <Nav.Link as={Link} to="/tarif" className={getNavLinkClass('/tarif')} onClick={() => handleLinkClick('/tarif')}>
              <FaEuroSign className="icon" /> Titres et tarifs
            </Nav.Link>
            
            {/* Lien Réseau avec sous-menu et icône de flèche alignée à droite avec espace 
            <Nav.Link className="nav-link" onClick={toggleSubmenu} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span><FaBus className="icon" /> Réseau</span>
              {showSubmenu ? (
                <IoIosArrowUp className="submenu-icon" style={{ marginRight: '10px' }} />
              ) : (
                <IoIosArrowDown className="submenu-icon" style={{ marginRight: '10px' }} />
              )}
            </Nav.Link>
            {showSubmenu && (
              <div className="submenu">
<Nav.Link as={Link} to="/reseau/tohm" className={getNavLinkClass('/reseau/tohm')} onClick={() => handleLinkClick('/reseau/tohm')} style={{ marginLeft: '15px', paddingLeft: '20px' }}>
  TOHM
</Nav.Link>
<Nav.Link as={Link} to="/reseau/axo" className={getNavLinkClass('/reseau/axo')} onClick={() => handleLinkClick('/reseau/axo')} style={{ marginLeft: '15px', paddingLeft: '20px' }}>
  AXO
</Nav.Link>
<Nav.Link as={Link} to="/reseau/tic" className={getNavLinkClass('/reseau/tic')} onClick={() => handleLinkClick('/reseau/tic')} style={{ marginLeft: '15px', paddingLeft: '20px' }}>
  TIC
</Nav.Link>
<Nav.Link as={Link} to="/reseau/duc" className={getNavLinkClass('/reseau/duc')} onClick={() => handleLinkClick('/reseau/duc')} style={{ marginLeft: '15px', paddingLeft: '20px' }}>
  DUC
</Nav.Link>
<Nav.Link as={Link} to="/reseau/corolis" className={getNavLinkClass('/reseau/corolis')} onClick={() => handleLinkClick('/reseau/corolis')} style={{ marginLeft: '15px', paddingLeft: '20px' }}>
  COROLIS
</Nav.Link>
              </div>
            )}*/}


            {   /*  <Nav.Link as={Link} to="/Itineraire" className={getNavLinkClass('/Itineraire')} onClick={() => handleLinkClick('/Itineraire')}>
              <CgArrowsExchange className="icon" /> Itineraire
            </Nav.Link>*/}

          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
