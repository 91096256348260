import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Spinner, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoCaretBackCircleOutline } from 'react-icons/io5';
import { TbPointFilled } from 'react-icons/tb';
import { TbGpsFilled } from 'react-icons/tb'



// Helper function to determine if a color is light or dark
function isColorDark(color) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
}

// Helper function to convert time to comparable format
function convertTimeToComparableFormat(time) {
  if (time.includes('<1')) {
    return 0; // Treat "<1 min" as 0 minutes
  } else if (time.includes('min')) {
    return parseInt(time); // Parse minutes
  } else {
    return parseInt(time.replace(':', '')); // Parse HH:MM format
  }
}

// Helper function to sort schedules
function sortSchedules(a, b) {
  const timeA = convertTimeToComparableFormat(a.time);
  const timeB = convertTimeToComparableFormat(b.time);

  return timeA - timeB;
}

// Helper function to get delay status
function getDelayStatus(delay) {
  if (delay === null || delay === undefined) {
    return 'Horaire prévu';
  }

  const delayInMinutes = Math.floor(delay / 60);

  if (delayInMinutes < 0) {
    return `En avance de ${Math.abs(delayInMinutes)} min`;
  } else if (delayInMinutes > 0) {
    return `Retardé de ${delayInMinutes} min`;
  } else {
    return 'À l\'heure';
  }
}

function BusStopDetails() {
  const { stopId, networkId } = useParams(); // Récupérer stopId et networkId
  const [stopName, setStopName] = useState('');
  const [cityName, setCityName] = useState('');  // Définir l'état pour cityName
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`/getBusStopDetails.php?stop_id=${stopId}&network_id=${networkId}`)
      .then(response => {
        if (response.data && response.data.stop_name) {
          setStopName(response.data.stop_name);
        } else {
          console.error('Stop name not found in response:', response.data);
          setStopName('Stop name not found');
        }
  
        // Récupérer et stocker le nom de la ville
        if (response.data && response.data.city_name) {
          setCityName(response.data.city_name);
        } else {
          console.error('City name not found in response:', response.data);
          setCityName('City not found');
        }
  
        if (response.data && response.data.schedules) {
          const sortedSchedules = (response.data.schedules || []).sort(sortSchedules);
          setSchedules(sortedSchedules);
        } else {
          setSchedules([]); // Clear schedules if none found
        }
  
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('Error fetching bus stop details:', error);
        setStopName('Error fetching stop name');
        setLoading(false); // Set loading to false even if there is an error
      });
  }, [stopId, networkId]);
  
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.get(`/getBusStopDetails.php?stop_id=${stopId}&network_id=${networkId}`)
        .then(response => {
          if (response.data) {
            setStopName(response.data.stop_name);

            const sortedSchedules = (response.data.schedules || []).sort(sortSchedules);
            setSchedules(sortedSchedules);
          }
        })
        .catch(error => {
          console.error('Error refreshing times:', error);
        });
    }, 5000);

    return () => clearInterval(intervalId);
  }, [stopId, networkId]);

  if (loading) {
    return (
      <div className="my-4 text-center">
        <Spinner animation="border" role="status" className="d-block mx-auto custom-spinner"> 
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="my-4 text-center">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="my-4">

      <Helmet>
            <title>Bus Connect - Arrêt : {stopName} ({cityName})</title>
          </Helmet>

<div
  style={{
    marginBottom: '20px',
    padding: '0 20px',
  }}
>
  <div style={{ textAlign: 'right', marginBottom: '10px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <p style={{ fontSize: '1.1em', color: '#555', margin: 0 }}>{cityName}</p> {/* Affiche le nom de la ville ici */}
    <TbGpsFilled style={{ color: '#555', marginLeft: '5px', fontSize: '1.5em' }} /> {/* Icône GPS plus grande */}
  </div>

  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <div 
      style={{ 
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '15px', // Espacement entre l'icône et le texte
      }} 
      onClick={() => window.history.back()} // Retourne en arrière lorsqu'on clique sur l'icône
    >
      <IoCaretBackCircleOutline size={50} style={{ color: '#0a78a4' }} /> {/* Taille et couleur de l'icône */}
    </div>
    <h4 style={{ margin: 0, fontSize: '1.5em', color: '#0A78A4' }}>{stopName}</h4>
  </div>
</div>



      <div 
  style={{
    backgroundColor: '#eeeeee',
    borderRadius: '100px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    justifyContent: 'center',
    marginLeft: '60px',
    marginRight: '60px',
    position: 'relative' // Ajout de cette ligne pour positionner la flèche
  }}
>
  <span style={{ marginRight: '5px', fontSize: '13px' }}>Mise à jour en temps réel</span>
  <TbPointFilled 
    style={{
      color: '#00b903',
      animation: 'fadeInOut 1s infinite',
      fontSize: '20px',
    }}
  />
  <div
    style={{
      position: 'absolute',
      bottom: '-10px', // Positionne la flèche en dessous
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #eeeeee', // Couleur de la flèche
    }}
  ></div>
</div>


      <style>
        {`
          @keyframes fadeInOut {
            0% { opacity: 0; }
            50% { opacity: 1; }
            100% { opacity: 0; }
          }
        `}
      </style>

      {schedules.length > 0 ? (
        schedules.map((schedule, index) => {
          const backgroundColor = `#${schedule.route_color}`;
          const textColor = isColorDark(backgroundColor) ? '#FFFFFF' : '#000000';

          const showDelayStatus = schedule.status === 'real_time' && schedule.delay !== undefined && schedule.delay !== null;
          const delayStatus = showDelayStatus ? getDelayStatus(schedule.delay) : null;

          const backgroundImage = schedule.status === 'canceled'
            ? `linear-gradient(135deg, rgba(255,0,0,0.3) 25%, transparent 25%, transparent 50%, rgba(255,0,0,0.3) 50%, rgba(255,0,0,0.3) 75%, transparent 75%, transparent)`
            : 'none';

          return (
            <div
              key={index}
              className="departure"
              style={{
                display: 'flex',
                alignItems: 'center',
                background: backgroundColor,
                color: textColor,
                padding: '10px',
                marginBottom: '2px',
                cursor: 'pointer',
                backgroundImage: backgroundImage,
                backgroundSize: '20px 20px',
              }}
            >
              <span style={{ flex: 1 }}>
                <span
                  style={{
                    fontSize: '2.5em',
                    fontWeight: 'bold',
                    color: textColor,
                    padding: '10px',
                    borderRadius: '5px',
                  }}
                >
                  {schedule.route_short_name} 
                </span>
              </span>
              <span style={{ flex: 5, marginLeft: '20px' }}>
                {schedule.status === 'canceled' && (
                  <span style={{ fontSize: '1.2em', fontWeight: 'bold', color: textColor, display: 'block', textTransform: 'uppercase' }}>
                   SUPPRIMÉ
                  </span>
                )}
                {showDelayStatus && (
                  <span style={{ fontSize: '1.2em', display: 'block', textTransform: 'uppercase', fontWeight: 'bold' }}>
                    {schedule.status === 'canceled' ? <s>{delayStatus}</s> : delayStatus}
                  </span>
                )}
                <span style={{ fontSize: '0.8em' }}>
                  <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: '5px' }} /> {schedule.trip_headsign}
                </span>
              </span>
              <span
                style={{
                  flex: 2,
                  textAlign: 'right',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <span className="no-click" style={{ fontSize: '2.0em', color: textColor, fontWeight: 'bold', textDecoration: schedule.status === 'canceled' ? 'line-through' : 'none' }}>
                  {schedule.time.split(' ')[0]}
                </span>
                <span className="time-text" style={{ fontSize: '0.8em', color: textColor, textTransform: 'uppercase' }}>
                  {schedule.time.split(' ')[1]}
                  {schedule.status === 'real_time' && (
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 20 20"
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        transform: 'rotate(90deg)',
                        margin: '5px',
                      }}
                    >
                      <g fill={textColor}>
                        <path
                          d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685"
                          style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}
                        ></path>
                        <path
                          d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z"
                          style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}
                        ></path>
                      </g>
                    </svg>
                  )}
                </span>
              </span>
            </div>
          );
        })
      ) : (
        <Alert variant="danger" className="text-center" style={{ margin: '20px' }}>
          <RiErrorWarningFill size="3em" style={{ marginBottom: '10px' }} />
          <p style={{ margin: 0 }}>Aucun horaire disponible.</p>
        </Alert>
      )}
    </div>
  );
}

export default BusStopDetails;
