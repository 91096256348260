import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Form, Container } from 'react-bootstrap';
import './tarifs.css';
import { Helmet } from 'react-helmet-async';
import { FaEuroSign } from 'react-icons/fa';

const Tarif = () => {
  const [networks, setNetworks] = useState([]); // Stocke la liste des réseaux
  const [selectedNetwork, setSelectedNetwork] = useState(''); // Stocke l'ID du réseau sélectionné
  const [tarifs, setTarifs] = useState([]); // Stocke la liste des tarifs
  const [selectedTarifId, setSelectedTarifId] = useState(null); // Stocke l'ID du tarif sélectionné

  // Récupérer les réseaux disponibles
  useEffect(() => {
    axios.get('/Networktarif.php') // API pour récupérer les réseaux
      .then(response => {
        setNetworks(response.data); // Met à jour les réseaux récupérés
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des réseaux:', error);
      });
  }, []);

  // Récupérer les tarifs en fonction du réseau sélectionné
  useEffect(() => {
    if (selectedNetwork) {
      axios.get(`/getTarifs.php?agency_id=${selectedNetwork}`) // Récupérer les tarifs du réseau
        .then(response => {
          setTarifs(response.data); // Met à jour les tarifs récupérés
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des tarifs:', error);
        });
    }
  }, [selectedNetwork]);

  // Fonction pour récupérer le nom du réseau à partir de l'ID sélectionné
  // et garder uniquement la partie avant le tiret
  const getNetworkName = () => {
    const network = networks.find(net => net.agency_id === selectedNetwork);
    if (network) {
      return network.agency_name.split(' - ')[0]; // Garde uniquement la première partie avant le tiret
    }
    return '';
  };

  const handleSelectTarif = (tarifId) => {
    setSelectedTarifId(tarifId === selectedTarifId ? null : tarifId); // Si on reclique sur le même tarif, on le déselectionne
  };

  // Fonction pour afficher le prix correctement (ou "GRATUIT" si 0)
  const displayPrice = (prix) => {
    const numericPrice = Number(prix); // Convertir en nombre

    if (isNaN(numericPrice)) {
      return 'Non disponible'; // Si ce n'est pas un nombre, afficher "Non disponible"
    }

    if (numericPrice === 0) {
      return 'GRATUIT';
    } else {
      return `${numericPrice.toFixed(2)} €`; // Affiche avec deux décimales
    }
  };

  return (
    <Container className="tarif-container mt-4">
        <Helmet>
      <title>Bus Connect - Titres et tarifs</title>
    </Helmet>
      <div style={{ textAlign: 'center' }}>
        <div style={{ color: '#0a78a4', fontSize: '5em', marginBottom: '10px', marginTop: '-40px' }}>
        <FaEuroSign className="icon" />
        </div>
        <div style={{ marginBottom: '30px' }}>
          <h2 style={{ display: 'inline', marginRight: '10px' }}>
          Titres et tarifs
          </h2>
         
        </div>
      </div>

      {/* Liste déroulante pour sélectionner un réseau */}
      <Form.Group controlId="networkSelect">

        <Form.Control 
          as="select" 
          value={selectedNetwork} 
          onChange={(e) => {
            setSelectedNetwork(e.target.value); // Met à jour l'ID du réseau sélectionné
            setSelectedTarifId(null); // Réinitialise les détails du tarif sélectionné
          }}
        >
          <option value="">Sélectionnez un réseau de bus</option>
          {networks.map(network => (
            <option key={network.agency_id} value={network.agency_id}>
              {network.agency_name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Affichage des tarifs en fonction du réseau sélectionné */}
      {selectedNetwork && (
        <div className="mt-4">
          <h2>Tickets disponibles pour le réseau {getNetworkName()}</h2> {/* Utilisation du nom du réseau */}
          <div className="row">
            {tarifs.length > 0 ? (
              tarifs.map(tarif => (
                <div className="col-md-12 tarif-mb-4" key={tarif.id}>
                  <Card onClick={() => handleSelectTarif(tarif.id)} className="tarif-card" style={{ cursor: 'pointer' }}>
                    <Card.Body>
                      <Card.Title className="tarif-card-title">{tarif.titre}</Card.Title>
                    </Card.Body>
                  </Card>
                  
                  {/* Affichage des détails juste en dessous de la carte sélectionnée */}
                  {selectedTarifId === tarif.id && (
                    <div className="mt-2">
                      <Card className="tarif-detail-card mb-3" style={{ border: '0px solid #ccc', borderRadius: '10px', padding: '20px' }}>
                        <Card.Header className="tarif-card-header text-white">
                          {tarif.titre}
                        </Card.Header>
                        <Card.Body className="tarif-card-body">
                          <Card.Text className="tarif-card-text">
                            {tarif.description}
                          </Card.Text>
                          <Card.Footer className="tarif-card-footer bg-white">
                            {displayPrice(tarif.prix)} {/* Utilisation de la fonction displayPrice */}
                          </Card.Footer>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>Aucun tarif disponible pour ce réseau.</p>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Tarif;
