import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import signblackLogo from './assets/signature_black.png'; // Assurez-vous que le chemin est correct
import { Helmet } from 'react-helmet-async';


const PrivacyPolicy = () => {
  return (
    <Container className="mt-4">
          <Helmet>
      <title>Bus Connect - Politique de Confidentialité</title>
    </Helmet>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="text-center mb-4">
                <strong>Politique de Confidentialité</strong>
              </Card.Title>
              
              <Card.Text className="mb-4">
                Cette page décrit notre politique en matière de confidentialité. Nous nous engageons à protéger
                vos données personnelles et à respecter votre vie privée.
              </Card.Text>

              <section className="mb-5">
                <h3 className="mb-3">Le but de cette politique de confidentialité</h3>
                <p className="mb-4">
                  Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des
                  données personnelles que nous recueillerons ainsi que les informations suivantes :
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Les données personnelles que nous recueillerons</ListGroup.Item>
                  <ListGroup.Item as="li">L’utilisation des données recueillies</ListGroup.Item>
                  <ListGroup.Item as="li">Qui a accès aux données recueillies</ListGroup.Item>
                  <ListGroup.Item as="li">Les droits des utilisateurs du site</ListGroup.Item>
                  <ListGroup.Item as="li">La politique de cookies du site</ListGroup.Item>
                </ListGroup>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Lois applicables</h3>
                <p className="mb-4">
                  Conformément au <em>Règlement général sur la protection des données</em> (RGPD), cette politique de
                  confidentialité est conforme aux règlements suivants :
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Les données à caractère personnel doivent être traitées de manière licite, loyale et transparente au regard de la personne concernée.</ListGroup.Item>
                  <ListGroup.Item as="li">Collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités.</ListGroup.Item>
                  <ListGroup.Item as="li">Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées.</ListGroup.Item>
                  <ListGroup.Item as="li">Exactes et, si nécessaire, tenues à jour.</ListGroup.Item>
                  <ListGroup.Item as="li">Conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées.</ListGroup.Item>
                  <ListGroup.Item as="li">Traitées de façon à garantir une sécurité appropriée des données à caractère personnel.</ListGroup.Item>
                </ListGroup>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Consentement</h3>
                <p className="mb-4">
                  Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Les conditions énoncées dans la présente politique de confidentialité</ListGroup.Item>
                  <ListGroup.Item as="li">La collecte, l’utilisation et la conservation des données énumérées</ListGroup.Item>
                </ListGroup>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Données personnelles que nous collectons</h3>
                <p className="mb-4">
                  <strong>Données collectées automatiquement</strong>
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Adresse IP</ListGroup.Item>
                  <ListGroup.Item as="li">Lieu</ListGroup.Item>
                  <ListGroup.Item as="li">Liens qu'un utilisateur clique tout en utilisant le site</ListGroup.Item>
                </ListGroup>

                <p className="mt-4 mb-4">
                  <strong>Données recueillies de façon non automatique</strong>
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Prénom et nom</ListGroup.Item>
                  <ListGroup.Item as="li">Âge</ListGroup.Item>
                  <ListGroup.Item as="li">Date de naissance</ListGroup.Item>
                  <ListGroup.Item as="li">Sexe</ListGroup.Item>
                </ListGroup>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Comment nous utilisons les données personnelles</h3>
                <p className="mb-4">
                  Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées
                  dans la présente politique ou indiquées sur les pages pertinentes de notre site.
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Avec qui nous partageons les données personnelles</h3>
                <p className="mb-4">
                  <strong>Employés</strong><br />
                  Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a
                  raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.
                </p>
                <p className="mb-4">
                  <strong>Tierces parties</strong><br />
                  Nous pouvons partager les données utilisateur avec des tiers aux fins suivantes :
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Pour atteindre les objectifs décrits dans la présente politique</ListGroup.Item>
                </ListGroup>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Combien de temps nous stockons les données personnelles</h3>
                <p className="mb-4">
                  Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les
                  fins pour lesquelles elles sont recueillies.
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Comment nous protégeons vos données personnelles</h3>
                <p className="mb-4">
                  Toutes les données stockées dans notre système sont bien sécurisées. Aucune donnée n'est publiée.
                  Nous prenons toutes les précautions raisonnables pour nous assurer que nos données d’utilisateur sont
                  sécurisées et que les utilisateurs sont protégés.
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Mineurs</h3>
                <p className="mb-4">
                  Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la
                  collecte de données.
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Vos droits en tant qu’utilisateur</h3>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li">Droit d’accès</ListGroup.Item>
                  <ListGroup.Item as="li">Droit de rectification</ListGroup.Item>
                  <ListGroup.Item as="li">Droit à l’effacement</ListGroup.Item>
                  <ListGroup.Item as="li">Droit de restreindre le traitement</ListGroup.Item>
                  <ListGroup.Item as="li">Droit à la portabilité des données</ListGroup.Item>
                  <ListGroup.Item as="li">Droit d'objection</ListGroup.Item>
                </ListGroup>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Comment modifier, supprimer ou contester les données recueillies</h3>
                <p className="mb-4">
                  Si vous souhaitez que vos renseignements soient supprimés ou modifiés, veuillez communiquer avec notre
                  agent de protection de la vie privée ici :
                </p>
                <p className="mb-4">
                  Clément WEIBEL<br />
                  60700, Pont-Sainte-Maxence<br />
                  weibelclement@gmail.com<br />
                  06 31 66 46 83
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Politique sur les cookies</h3>
                <p className="mb-4">
                  Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est
                  de recueillir des données relatives aux habitudes de navigation de l’utilisateur.
                </p>
                <ListGroup as="ol" variant="flush">
                  <ListGroup.Item as="li" className="d-flex align-items-start">
                    <strong style={{ minWidth: "150px" }}>Cookies fonctionnels</strong>
                    <span className="ml-3">
                      Ces cookies sont nécessaires pour le bon fonctionnement du site Web.
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="d-flex align-items-start">
                    <strong style={{ minWidth: "150px" }}>Cookies de performance</strong>
                    <span className="ml-3">
                      Ces cookies recueillent des informations sur la façon dont les visiteurs utilisent le site Web.
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" className="d-flex align-items-start">
                    <strong style={{ minWidth: "150px" }}>Cookies de ciblage</strong>
                    <span className="ml-3">
                      Ces cookies sont utilisés pour afficher des publicités en fonction de vos centres d’intérêt.
                    </span>
                  </ListGroup.Item>
                </ListGroup>
                <p className="mt-4">
                  Vous pouvez configurer votre navigateur pour refuser les cookies si vous le souhaitez.
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Modifications de notre politique de confidentialité</h3>
                <p className="mb-4">
                  Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Nous vous informerons de
                  toute modification en publiant la nouvelle politique sur notre site.
                </p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">Contact</h3>
                <p className="mb-4">
                  Pour toute question concernant cette politique de confidentialité, veuillez nous contacter :
                </p>
                <p>
                  Clément WEIBEL<br />
                  60700, Pont-Sainte-Maxence<br />
                  weibelclement@gmail.com<br />
                  06 31 66 46 83 <br></br>
              
                </p>
                <div style={{ 
  display: "flex", 
  justifyContent: "center", 
  alignItems: "center", 
  height: "10vh" 
}}>
  <img 
    src={signblackLogo} 
    style={{ 
      maxWidth: "200px"
    }} 
  />
</div>

              </section>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
