import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './App.css';
import signLogo from './assets/signe.png'; // Assurez-vous que le chemin est correct
import bcLogo from './assets/logo_footer.svg'; // Assurez-vous que le chemin est correct
import transportLogo from './assets/seda.svg'; // Assurez-vous que le chemin est correct
import { FaFacebook, FaInstagram, FaExclamationCircle } from 'react-icons/fa';
import { FaBurst } from "react-icons/fa6";


const Footer = () => {
  const location = useLocation(); // Récupère l'URL actuelle

  return (
    <footer className="footer">
    <div className="footer-content">
  <div className="footer-left">
    <img src={bcLogo} className="footer-logo" alt="Logo" />
  </div>
  <div className="footer-right">
    <img src={signLogo} className="footer-sign" alt="Signature" />
  </div>
</div>

      <div className="footer-links" style={{ textAlign: 'center', width: '100%' }}>
        <Link to="/mentions" className="footer-link">
          <button
            type="button"
            className={`btn btn-outline-light btn-lg footer-button ${location.pathname === '/mentions' ? 'active' : ''}`}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            Mentions légales
          </button>
        </Link>
        <Link to="/privacy_policy" className="footer-link">
          <button
            type="button"
            className={`btn btn-outline-light btn-lg footer-button ${location.pathname === '/privacy_policy' ? 'active' : ''}`}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            Confidentialité
          </button>
        </Link>
        <Link to="/incident" className="footer-link">
          <button
            type="button"
            className={`btn btn-outline-light btn-lg footer-button ${location.pathname === '/incident' ? 'active' : ''}`}
            style={{ width: '100%', marginBottom: '10px' }}
          >
            Incident en cours &nbsp; <FaBurst style={{ marginTop: '-5px' }}/>
          </button>
        </Link>
      </div>
    
        <img src={transportLogo} className="footer-data-logo logo-rounded" alt="Data source logo" />
      
    </footer>
  );
};

export default Footer;
