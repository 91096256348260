import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, FormControl, Button, Row, Col, Placeholder, Modal } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Polyline, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { IoArrowUndoCircleSharp } from "react-icons/io5";
import { GiNightSleep } from "react-icons/gi";
import { TbArrowBackUp } from "react-icons/tb";
import toast, { Toaster } from 'react-hot-toast';
import { TfiClose } from "react-icons/tfi";


// Import React Icons
import { FaBusSimple } from "react-icons/fa6";
import { RiExpandLeftRightFill } from "react-icons/ri";
import { PiSealWarning } from "react-icons/pi";
import { PiWarningCircleFill } from "react-icons/pi";
import { TbGpsFilled } from "react-icons/tb";
import { MdChangeCircle } from 'react-icons/md';
import { FaClock } from "react-icons/fa";
import { FaCaretLeft } from "react-icons/fa6";
import { FaCaretRight } from "react-icons/fa6";




delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const AlertIcon = () => (
  <svg viewBox="0 0 14 16" width="32" height="32" className="alert-icon">
    <path fillRule="evenodd" d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"></path>
  </svg>
);

// Définition de la fonction isColorDark
const isColorDark = (color) => {
  if (!color) {
    return false;
  }
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma < 128;
};

// Fonction createVehicleIcon
const createVehicleIcon = (color, textColor, bearing, opacity) => {
  const isDarkBackground = isColorDark(color);
  const adjustedTextColor = isDarkBackground ? 'white' : 'black';
  const adjustedArrowColor = isDarkBackground ? 'white' : 'black';

  const html = renderToStaticMarkup(
    <div className="vehicle-icon" style={{ zIndex: 1000, backgroundColor: color, transform: `rotate(${bearing}deg)`, opacity }}>
      <div className="circle-background" style={{
        backgroundColor: color,
        opacity: 0.3,
        position: 'absolute',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}></div>
      <FaBusSimple className="icon" style={{ color: adjustedTextColor }} size={22} />
      <div style={{
        width: 0,
        height: 0,
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        borderBottom: `30px solid ${color}`,
        position: 'absolute',
        top: '-15px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}></div>
      <div style={{
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: `12px solid ${adjustedArrowColor}`,
        position: 'absolute',
        top: '-8px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}></div>
    </div>
  );

  return L.divIcon({
    className: '',
    html,
    iconSize: [35, 35]
  });
};

// Le reste de votre code...


const getCurrentLocalDate = () => {
  const now = new Date();
  const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  const localTimeMs = now.getTime() - offsetMs;
  const localDate = new Date(localTimeMs);
  return localDate.toISOString().split('T')[0];
};

const checkVehicleData = (vehicleTimestamp) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const timeDiff = currentTime - vehicleTimestamp;
  if (timeDiff > 150) return { remove: true, opacity: 1 };
  if (timeDiff > 80) return { remove: false, opacity: 0.6 };
  return { remove: false, opacity: 1 };
};

// Fonction utilitaire pour vérifier si la date est aujourd'hui
const isToday = (date) => {
  const today = new Date().toISOString().split('T')[0];
  return date === today;
};

// Détermination du jour ou de la nuit
const isDayTime = () => {
  const currentHour = new Date().getHours();
  return currentHour >= 6 && currentHour < 20; // Jour entre 7h et 19h
};

const Schedule = () => {
  const { networkId, routeId, directionId } = useParams();
  const [schedules, setSchedules] = useState([]);
  const [organizedSchedules, setOrganizedSchedules] = useState({});
  const [tripIds, setTripIds] = useState([]);
  const [routeInfo, setRouteInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(getCurrentLocalDate());
  const [selectedTime, setSelectedTime] = useState(new Date().toTimeString().split(' ')[0].substring(0, 5));
  const [currentTripIndex, setCurrentTripIndex] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [stops, setStops] = useState([]);
  const [shapePoints, setShapePoints] = useState([]);
  const [routeColor, setRouteColor] = useState('#000000');
  const [directions, setDirections] = useState([]);
  const [realTimeVehicles, setRealTimeVehicles] = useState([]);
  const [realTimeSchedules, setRealTimeSchedules] = useState({});
  const [shouldCenterMap, setShouldCenterMap] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [serviceEnded, setServiceEnded] = useState(false);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedDisruption, setSelectedDisruption] = useState(null);
  const [disruptions, setDisruptions] = useState([]); // Ajout de l'état disruptions
  const [buttonText, setButtonText] = useState('Géolocalisation');
  const [vehicleCount, setVehicleCount] = useState(0);
  const [isDay, setIsDay] = useState(isDayTime());

  useEffect(() => {
    fetchDirections();
    fetchSchedules(selectedDate, selectedTime);
    fetchRouteInfo();
    fetchStops();
  }, [networkId, routeId, directionId, selectedDate, selectedTime]);

  useEffect(() => {
    if (tripIds.length > 0) {
      fetchShapePoints(tripIds[currentTripIndex]);
    }
  }, [currentTripIndex, tripIds]);

  useEffect(() => {
    if (routeInfo) {
      fetchRealTimeVehicles();
      if (isToday(selectedDate)) {
        fetchRealTimeSchedules();
      }
      const interval = setInterval(() => {
        fetchRealTimeVehicles();
        if (isToday(selectedDate)) {
          fetchRealTimeSchedules();
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [routeInfo, directionId, selectedDate]);

  useEffect(() => {
    if (stops.length > 0) {
      fetchCities();
    }
  }, [stops]);

  const handleShowModal = (disruption) => {
    setSelectedDisruption(disruption);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDisruption(null);
  };

  const fetchRouteInfo = () => {
    axios.get(`/getBusLines.php?route_info=1&route_id=${routeId}&network_id=${networkId}`)
      .then(response => {
        if (response.data) {
          setRouteInfo(response.data);
          setRouteColor(`#${response.data.route_color}`);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the route info!', error);
      });
  };

  const fetchDirections = () => {
    axios.get(`/getDirections.php?route_id=${routeId}&network_id=${networkId}`)
      .then(response => {
        setDirections(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the directions!', error);
      });
  };

  useEffect(() => {
    const texts = ['Géolocalisation', 'Plan intéractif'];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % texts.length;
      setButtonText(texts[currentIndex]);
    }, 5000);

    return () => clearInterval(interval); // Nettoie l'intervalle lors du démontage du composant
  }, []);

  const fetchSchedules = (date, time) => {
    console.log("Fetching schedules with the following parameters:");
    console.log("Route ID:", routeId);
    console.log("Direction ID:", directionId);
    console.log("Date:", date);
    console.log("Network ID:", networkId);

    axios.get(`/getSchedules.php?route_id=${routeId}&direction_id=${directionId}&date=${date}&network_id=${networkId}`)
      .then(response => {
        console.log("Server response:", response.data);

         // Extraction des horaires et des perturbations
         const { schedules, disruptions } = response.data;

         // Mise à jour des perturbations
         setDisruptions(disruptions || []);
         
// Si des perturbations existent, afficher un toast pour chaque perturbation avec un décalage d'1 seconde
if (disruptions && disruptions.length > 0) {
  disruptions.forEach((disruption, index) => {
    setTimeout(() => {
      const toastId = toast.error(
        <div style={{ position: 'relative', paddingBottom: '8px' }}> {/* Ajout de padding pour espacer la barre */}
          <button
            onClick={() => toast.dismiss(toastId)} // Fermer le toast au clic
            style={{
              position: 'absolute',
              top: '-10px',    // Positionnement en haut
              right: '-10px',  // Positionnement complètement à droite
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: '10px', // Ajout d'un petit padding pour plus de clarté
              color: '#d9534f',
            }}
          >
            <TfiClose size={30} /> {/* Taille de l'icône */}
          </button>
          <div style={{ paddingRight: '40px', marginBottom: '10px' }}> {/* Dégagement pour éviter que le texte ne chevauche la croix */}
            <span style={{ fontWeight: 'bold', color: 'black' }}>{disruption.status}</span> {/* Noir et en gras */}
            <span style={{ color: '#292929' }}> - </span> {/* Tiret séparateur */}
            <span style={{ color: '#292929' }}>{disruption.message}</span> {/* Couleur #292929 */}
          </div>
          {/* Barre de progression prenant toute la largeur du toast */}
          <div style={{ position: 'absolute', bottom: '0px', left: '0px', right: '0px', height: '6px', backgroundColor: '#f0f0f0', borderRadius: '3px' }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#d9534f',
                borderRadius: '3px', // Barre arrondie
                animation: `progressBar 10s linear forwards, fadeBlink 3s ease-in-out 7s`, // Ajout du fade in/out à 3s
              }}
            />
          </div>
        </div>,
        {
          duration: 10000, // Durée de 10 secondes pour chaque notification
          style: {
            border: '1px solid #d9534f',
            padding: '16px',
            color: '#d9534f',
            position: 'relative',
          },
          iconTheme: {
            primary: '#d9534f',
            secondary: '#FFFAEE',
          },
        }
      );
    }, index * 2000); // Délai d'apparition de 2 secondes pour chaque toast suivant
  });
}
       
        console.log("Extracted schedules:", schedules);
        console.log("Extracted disruptions:", disruptions);

        if (!schedules || schedules.length === 0) {
          console.log("No schedules found, showing alert.");
          setShowAlert(true);
          setServiceEnded(false);
          return;
        }

        // Organiser les horaires en fonction de leur trip_id
        const organizedSchedules = {};
        schedules.forEach(schedule => {
          if (!organizedSchedules[schedule.trip_id]) {
            organizedSchedules[schedule.trip_id] = [];
          }
          organizedSchedules[schedule.trip_id].push(schedule);
        });

        // Trier les trip_ids en fonction de l'heure d'arrivée
        const tripIds = Object.keys(organizedSchedules).sort((a, b) => {
          const timeA = organizedSchedules[a][0].arrival_time;
          const timeB = organizedSchedules[b][0].arrival_time;
          return timeA.localeCompare(timeB);
        });

        // Trouver l'index du trip_id en fonction de l'heure sélectionnée
        const initialTripIndex = tripIds.findIndex(tripId => {
          const trip = organizedSchedules[tripId];
          return trip.some(schedule => schedule.arrival_time >= time);
        });

        // Mise à jour des états avec les nouvelles données
        setSchedules(schedules);
        setOrganizedSchedules(organizedSchedules);
        setTripIds(tripIds);
        setCurrentTripIndex(initialTripIndex !== -1 ? initialTripIndex : 0);
        setDisruptions(disruptions || []); // Mise à jour des perturbations avec un tableau vide si aucune perturbation n'est renvoyée
        console.log("Updated disruptions state:", disruptions);

        

        // Vérifier si le service est terminé
        const lastSchedule = schedules[schedules.length - 1];
        const lastArrivalTime = new Date(`${date}T${lastSchedule.arrival_time}`);
        const now = new Date();
        console.log("Last arrival time:", lastArrivalTime);
        console.log("Current time:", now);

        if (lastArrivalTime < now) {
          console.log("Service has ended.");
          setServiceEnded(true);
          setShowAlert(true);
        } else {
          console.log("Service is still running.");
          setServiceEnded(false);
          setShowAlert(false);
        }
      })
      .catch(error => {
        console.error("Error fetching schedules:", error);
      });
  };

  const fetchStops = () => {
    axios.get(`/getStops.php?route_id=${routeId}&direction_id=${directionId}&network_id=${networkId}`)
      .then(response => {
        setStops(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the stops!', error);
      });
  };

  const fetchShapePoints = (tripId) => {
    axios.get(`/getShapes.php?trip_id=${tripId}&network_id=${networkId}`)
      .then(response => {
        const sortedShapePoints = response.data
          .map(point => [parseFloat(point.shape_pt_lat), parseFloat(point.shape_pt_lon)])
          .sort((a, b) => a[2] - b[2]);
        setShapePoints(sortedShapePoints);
        if (response.data.length > 0) {
          setRouteColor(`#${response.data[0].route_color}`);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the shape points!', error);
      });
  };

  const fetchVehicleDetails = async (vehicleId) => {
    try {
      const response = await axios.get(`/getVehicleDetails.php?vehicle_id=${vehicleId}`);
      return response.data;
    } catch (error) {
      console.error('There was an error fetching the vehicle details!', error);
      return null;
    }
  };

const fetchRealTimeVehicles = () => {
    const apiUrl = `https://api.oisemob.cityway.fr/dataflow/vehicule-tc-tr/download?provider=${routeInfo.real_time}&dataFormat=gtfs-rt-json`;

    axios.get(apiUrl)
      .then(async (response) => {
        if (response.data && response.data.entity) {
          const vehicles = response.data.entity.filter(vehicle => 
            vehicle.vehicle.trip.route_id === routeId &&
            vehicle.vehicle.trip.direction_id === parseInt(directionId)
          );
          setVehicleCount(vehicles.length); // Met à jour le nombre de véhicules en circulation

          const vehiclesWithDetails = await Promise.all(
            vehicles.map(async (entity) => {
              const { remove, opacity } = checkVehicleData(entity.vehicle.timestamp);
              const vehicleDetails = await fetchVehicleDetails(entity.vehicle.vehicle.id);

              return {
                id: entity.vehicle.vehicle.id,
                tripId: entity.vehicle.trip.trip_id,
                position: entity.vehicle.position,
                bearing: entity.vehicle.position.bearing,
                routeId: entity.vehicle.trip.route_id,
                routeColor: `#${routeInfo.route_color}`,
                remove,
                opacity,
                stop_id: entity.vehicle.stop_id,
                brand: vehicleDetails ? vehicleDetails.brand : 'Unknown',
                model: vehicleDetails ? vehicleDetails.model : 'N/A',
                air_conditioning: vehicleDetails ? vehicleDetails.air_conditioning : 0,
                number_of_doors: vehicleDetails ? vehicleDetails.number_of_doors : 0
              };
            })
          );

          const filteredVehicles = vehiclesWithDetails.filter(vehicle => !vehicle.remove);
          setRealTimeVehicles(filteredVehicles);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the real-time vehicle data!', error);
      });
  };

  const fetchRealTimeSchedules = () => {
    if (routeInfo && routeInfo.real_time) {
      const apiUrl = `https://api.oisemob.cityway.fr/dataflow/horaire-tc-tr/download?provider=${routeInfo.real_time}&dataFormat=gtfs-rt-json`;

      axios.get(apiUrl)
        .then(response => {
          if (response.data && response.data.entity) {
            const newRealTimeSchedules = {};

            response.data.entity.forEach(entity => {
              if (entity.trip_update && entity.trip_update.stop_time_update) {
                const tripId = entity.trip_update.trip.trip_id;
                if (!newRealTimeSchedules[tripId]) {
                  newRealTimeSchedules[tripId] = [];
                }

                entity.trip_update.stop_time_update.forEach(update => {
                  const time = update.departure && update.departure.time
                    ? update.departure.time
                    : (update.arrival && update.arrival.time
                      ? update.arrival.time
                      : null);

                  if (time) {
                    newRealTimeSchedules[tripId].push({
                      stop_id: update.stop_id,
                      stop_sequence: update.stop_sequence,
                      time: convertTimestampToTime(time),
                      schedule_relationship: update.schedule_relationship
                    });
                  }
                });

                newRealTimeSchedules[tripId].sort((a, b) => a.stop_sequence - b.stop_sequence);
              }
            });

            console.log("Fetched real-time schedules:", newRealTimeSchedules);
            setRealTimeSchedules(newRealTimeSchedules);
          }
        })
        .catch(error => {
          console.error('There was an error fetching the real-time schedules!', error);
        });
    }
  };

  const fetchCities = async () => {
    setLoadingCities(true);
    const cities = [];
    const stopsCoordinates = stops.map(stop => ({ lat: stop.stop_lat, lon: stop.stop_lon }));

    const cache = localStorage.getItem('citiesCache');
    let cachedCities = cache ? JSON.parse(cache) : {};

    for (const stop of stopsCoordinates) {
      const cacheKey = `${stop.lat},${stop.lon}`;

      if (cachedCities[cacheKey]) {
        cities.push(cachedCities[cacheKey]);
      } else {
        try {
          const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
            params: {
              lat: stop.lat,
              lon: stop.lon,
              format: 'json',
              addressdetails: 1,
              'accept-language': 'fr'
            }
          });

          const city = response.data.address.city ||
            response.data.address.town ||
            response.data.address.village;

          if (city) {
            const cityData = { latitude: stop.lat, longitude: stop.lon, city_name: city };
            cities.push(cityData);
            cachedCities[cacheKey] = cityData;
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des données de la ville:', error);
        }
      }
    }

    localStorage.setItem('citiesCache', JSON.stringify(cachedCities));
    setCities(cities);
    setLoadingCities(false);
  };

  const haversineDistance = (coords1, coords2) => {
    const toRad = x => x * Math.PI / 180;
    const lat1 = coords1[0];
    const lon1 = coords1[1];
    const lat2 = coords2[0];
    const lon2 = coords2[1];

    const R = 6371;
    const x1 = lat2 - lat1;
    const dLat = toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = toRad(x2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const findClosestCity = (stopLat, stopLon) => {
    if (loadingCities) {
      return (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={5} />
        </Placeholder>
      );
    }

    if (!Array.isArray(cities)) {
      console.error('Cities is not an array:', cities);
      return 'Unknown';
    }

    let closestCity = null;
    let minDistance = Infinity;

    cities.forEach(city => {
      const cityLat = parseFloat(city.latitude);
      const cityLon = parseFloat(city.longitude);
      const distance = haversineDistance([stopLat, stopLon], [cityLat, cityLon]);

      if (distance < minDistance) {
        minDistance = distance;
        closestCity = city.city_name;
      }
    });

    return closestCity || 'Unknown';
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
  };

  const handleTimeChange = (e) => {
    const time = e.target.value;
    setSelectedTime(time);
  };

  const handleNextTrip = () => {
    if (currentTripIndex < tripIds.length - 1) {
      setCurrentTripIndex(currentTripIndex + 1);
    }
  };

  const handlePrevTrip = () => {
    if (currentTripIndex > 0) {
      setCurrentTripIndex(currentTripIndex - 1);
    }
  };

  const handleShowMap = () => {
    setShowMap(true);
    setShouldCenterMap(true);
  };

  const handleCloseMap = () => {
    setShowMap(false);
  };

  const handleDirectionChange = (e) => {
    const newDirectionId = e.target.value;
    navigate(`/schedule/${networkId}/${routeId}/${newDirectionId}`);
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().split(' ')[0];
  };

  const formatArrivalTime = (time) => {
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours) % 24;
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convertir les secondes en millisecondes
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const calculateMinutesRemaining = (time) => {
    const now = new Date();
    const [hours, minutes] = time.split(':').map(Number);
    const targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    const diffInMs = targetTime - now;
    return Math.ceil(diffInMs / (1000 * 60)); // Convertir de millisecondes en minutes
  };

  const getStopName = (stopId) => {
    const stopDetails = stops.find(stop => stop.stop_id === stopId);
    return stopDetails ? stopDetails.stop_name : 'Arrêt inconnu';
  };

  const getNextStops = (tripId, currentStopId) => {
    const stops = realTimeSchedules[tripId] || [];

    if (stops.length === 0) {
      return [];  // Si aucune donnée n'est disponible, retourne un tableau vide
    }

    // Trouver l'indice de l'arrêt en cours
    let currentStopIndex = stops.findIndex(stop => stop.stop_id === currentStopId);

    // Si l'arrêt en cours n'est pas trouvé, commencer au début de la liste
    if (currentStopIndex === -1) {
      currentStopIndex = 0;
    }

   // Inclure l'arrêt en cours et les trois suivants
   const nextStops = stops.slice(currentStopIndex, currentStopIndex + 4);

   return nextStops.map(stop => {
     let adjustedTime;
     if (typeof stop.time === 'number') {
       // Si l'heure est un timestamp en secondes, on le convertit en objet Date
       adjustedTime = new Date(stop.time * 1000); // Convertir les secondes en millisecondes
     } else if (typeof stop.time === 'string') {
       // Si l'heure est au format HH:MM, on la convertit en objet Date
       const [hours, minutes] = stop.time.split(':').map(Number);
       adjustedTime = new Date();
       adjustedTime.setHours(hours, minutes);
     } else {
       console.error('Format de temps invalide:', stop.time);
       return stop;
     }
 
     // Ajouter 1 minute
     adjustedTime.setMinutes(adjustedTime.getMinutes() + 0.5);
 
   // Convertir l'heure ajustée en chaîne au format HH:MM
   const adjustedTimeString = adjustedTime.toTimeString().substr(0, 5);

   const minutesRemaining = calculateMinutesRemaining(adjustedTimeString);
   return {
     stopId: stop.stop_id,
     stopName: getStopName(stop.stop_id),
     time: adjustedTimeString, // Utiliser l'heure ajustée
     minutesRemaining: minutesRemaining,
     atStop: minutesRemaining <= 0 && minutesRemaining > -1.5, // À l'arrêt : entre 0s et 90s
     alreadyPassed: minutesRemaining <= -1.5 && minutesRemaining > -3, // Déjà passé : entre 90s et 180s
     lessThanOneMinute: minutesRemaining < 1.5 && minutesRemaining >= 0 // <1 min : entre 0s et 90s mais pas encore à l'arrêt
   };
 }).filter(stop => stop.minutesRemaining > -3); // On garde les arrêts passés récemment (moins de 180s)
};

const renderVehiclePopup = (vehicle) => {
 console.log(vehicle); // Ajoute cette ligne pour vérifier ce qui est passé à la fonction

 const nextStops = getNextStops(vehicle.tripId, vehicle.stop_id);

 const lastStop = realTimeSchedules[vehicle.tripId]?.slice(-1)[0];
 const lastStopName = lastStop ? getStopName(lastStop.stop_id) : 'Arrêt inconnu';
 const minutesToLastStop = lastStop ? calculateMinutesRemaining(lastStop.time) : null;

 const brand = vehicle.brand && vehicle.brand !== "Unknown" ? vehicle.brand : ''; // Suppression des parenthèses
 const model = vehicle.model && vehicle.model !== "Unknown" ? vehicle.model : 'N/A';
 const airConditioning = vehicle.air_conditioning !== undefined && vehicle.air_conditioning !== null && vehicle.air_conditioning !== 0;
 const numberOfDoors = vehicle.number_of_doors !== undefined && vehicle.number_of_doors !== null && vehicle.number_of_doors !== 0 ? vehicle.number_of_doors : 'N/A';

 return (
   <div style={{ position: 'relative', paddingTop: '20px' }}> {/* Ajuste la marge supérieure */}
     <div
       style={{
         fontSize: '18px',
         fontWeight: 'bold',
         borderRadius: '15px',
         padding: '5px',
         color: 'black',
         border: `4px solid ${vehicle.routeColor}`,
         position: 'absolute',
         display: 'block',
         textAlign: 'center',
         width: '100px',
         top: '-40px',
         left: '50%',
         transform: 'translateX(-50%)',
         backgroundColor: 'white'
       }}
     >
       {routeInfo.route_short_name}

       <div
         style={{
           position: 'absolute',
           bottom: '-15px', // Positionne la flèche juste en dessous du div
           left: '50%',
           transform: 'translateX(-50%)',
           width: '0',
           height: '0',
           borderLeft: '15px solid transparent',
           borderRight: '15px solid transparent',
           borderTop: `15px solid ${vehicle.routeColor}` // Utilisation de la couleur dynamique pour la flèche
         }}
       ></div>
     </div>

     {brand !== '' ? (
       <div style={{ textAlign: 'center', marginBottom: '5px' }}>
         <strong style={{ fontSize: '2em', lineHeight: '1.2' }}>{brand}</strong> {/* Ajuste la taille de la police et la hauteur de ligne */}
         <div style={{ fontSize: '1em', color: 'gray' }}>{model}</div> {/* Modèle en gris, plus petit */}
       </div>
     ) : (
       <div style={{ textAlign: 'center', marginBottom: '5px' }}>
         <strong style={{ fontSize: '2.5em' }}>{vehicle.id} {brand}</strong>
       </div>
     )}

     {/* Affichage des autres informations si elles sont disponibles */}
     {brand !== '' && (
       <>
         <div>
           Climatisation:
           {airConditioning ? (
             <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '5px' }} />
           ) : (
             <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginLeft: '5px' }} />
           )}
         </div>
         <div>Nombre de portes: {numberOfDoors}</div>
       </>
     )}

     <div style={{ marginTop: '10px' }}>
       <strong>Prochains arrêts :</strong>
       {nextStops.length > 0 ? (
         nextStops.map((stop, idx) => (
           <div style={{ marginTop: '5px' }} key={idx}>
             {stop.stopName} <RiExpandLeftRightFill style={{ marginRight: '4px' }} />
             {stop.atStop ? (
               'à l\'arrêt'
             ) : stop.alreadyPassed ? (
               'déjà passé'
             ) : stop.lessThanOneMinute ? (
               '<1 min'
             ) : `${stop.minutesRemaining} min`}
             {!stop.atStop && !stop.alreadyPassed && (
               <svg width="1em" height="1em" viewBox="0 0 20 20" style={{ height: '7px', marginTop: '-12px', marginLeft: '-5px', transform: 'rotate(90deg)' }}>
                 <g fill="#FBAC2A">
                   <path d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685" style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}></path>
                   <path d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z" style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}></path>
                 </g>
               </svg>
             )}
           </div>
         ))
       ) : (
         <div>Aucun prochain arrêt trouvé.</div>
       )}
     </div>

      {lastStop && (
        <div style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '15px', textAlign: 'center', }}>
          {minutesToLastStop <= 0 ? (
            <div style={{ fontSize: '30px', lineHeight: '2', }}>
              arrivée
            </div>
          ) : (
            <>
              Arrivée à {lastStopName} dans <br />
              <div style={{ fontSize: '30px', lineHeight: '2', }}>
                {minutesToLastStop + 0} min
                <svg width="1.5em" height="1.5em" viewBox="0 0 20 20" style={{ height: '30px', transform: 'rotate(125deg)' }}>
                  <g fill="#FBAC2A">
                    <path d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685" style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}></path>
                    <path d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z" style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}></path>
                  </g>
                </svg>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};


  const currentTripId = tripIds[currentTripIndex];
  const currentTrip = organizedSchedules[currentTripId] || [];

  const isToday = (date) => date === new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (showMap && mapRef.current && shouldCenterMap) {
      const map = mapRef.current;
      if (shapePoints.length > 0) {
        map.fitBounds(shapePoints);
      } else if (stops.length > 0) {
        const stopBounds = stops.map(stop => [stop.stop_lat, stop.stop_lon]);
        map.fitBounds(stopBounds);
      }
      setShouldCenterMap(false);
    }
  }, [showMap, shapePoints, stops, shouldCenterMap]);

  const MapBoundsSetter = () => {
    const map = useMap();

    useEffect(() => {
      if (shapePoints.length > 0 && shouldCenterMap) {
        map.fitBounds(shapePoints);
        setShouldCenterMap(false);
      } else if (stops.length > 0 && shouldCenterMap) {
        const stopBounds = stops.map(stop => [stop.stop_lat, stop.stop_lon]);
        map.fitBounds(stopBounds);
        setShouldCenterMap(false);
      }
    }, [map, shapePoints, stops, shouldCenterMap]);

    return null;
  };

  const formatTimeDifference = (diffInMinutes) => {
    const absDiff = Math.abs(diffInMinutes);
    return `${absDiff} min`;
  };

  const isColorDark = (color) => {
    if (!color) {
      return false;
    }
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 128;
  };

  // Trouver la direction correspondante à `directionId`
const selectedDirection = directions.find(direction => direction.direction_id === parseInt(directionId));
const directionName = selectedDirection ? selectedDirection.headsign : '';

const convertToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


  return (
    <Container>
       <Toaster />
    <Helmet>
      <title>Bus Connect - {networkId} - {routeInfo ? routeInfo.route_short_name : ''} vers {directionName}</title>
    </Helmet>
  
    {/* Fixed Header */}
    <div className="fixed-header" style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000, backgroundColor: 'white', boxShadow: `0 8px 5px ${convertToRgba(routeColor, 0.1)}` }}>
  
      {/* Back Arrow (IoArrowUndoCircleSharp) */}
      <div style={{ position: 'absolute', top: '5px', left: '35px', zIndex: 1001 }}>
      <TbArrowBackUp
          style={{ fontSize: '45px', color: isColorDark(routeColor) ? 'white' : 'black', cursor: 'pointer' }} 
          onClick={() => window.location.href = '/'} 
        />
      </div>
  
      {/* Full-width Half-circle with Route Info */}
      <div style={{ 
          position: 'relative', 
          width: '100%', 
          height: '60px', 
          backgroundColor: routeColor, 
          borderBottomLeftRadius: '80px', 
          borderBottomRightRadius: '0px', 
          display: 'flex', 
          marginLeft: '15px',
          alignItems: 'center', 
          justifyContent: 'center', 
          overflow: 'hidden',
          boxShadow: `0 10px 10px ${convertToRgba(routeColor, 0.2)}`
        }}>
  
        {/* Route Info */}
        {routeInfo ? (
          <b className="line-text" style={{ fontSize: '2em', color: isColorDark(routeColor) ? 'white' : 'black' }}>
            {routeInfo.route_short_name}
          </b>
        ) : (
          <b className="line-text" style={{ fontSize: '2em', color: isColorDark(routeColor) ? 'white' : 'black' }}>
            N/A
          </b>
        )}
      </div>
  
      {/* Elements below the half-circle inside the fixed header */}
      <div style={{ padding: '15px' }}>
        {/* Direction Selection */}
        <Row className="mb-4" style={{ marginTop: '10px' }}>
          <Col xs={12} sm={8} md={6} lg={4}>
            <div className="custom-select-wrapper">
              <div className="static-label">Vers</div>
              <Form.Control 
                as="select" 
                value={directionId} 
                onChange={handleDirectionChange} 
                className="custom-select text-truncate"
              >
                {directions.map((direction, index) => (
                  <option 
                    key={index} 
                    value={direction.direction_id} 
                    className="option text-wrap"
                  >
                    {direction.headsign}
                  </option>
                ))}
              </Form.Control>
              <MdChangeCircle className="border-icon" />
            </div>
          </Col>
        </Row>
  
        {/* Date and Time Picker */}
        <Form className="mb-4">
          <Row style={{ marginBottom: '-30px' }}>
            <Col>
              <FormControl
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Col>
            <Col>
              <FormControl
                type="time"
                value={selectedTime}
                onChange={handleTimeChange}
              />
            </Col>
          </Row>
        </Form>
  
        {/* Navigation Buttons */}
        {!serviceEnded && !showAlert && (
          <div className="center-button" style={{ marginTop: '70px' }}> 
            <button
              onClick={handlePrevTrip}
              disabled={currentTripIndex === 0}
              className={`custom-button ${currentTripIndex === 0 ? 'disabled-button' : ''}`}
              style={{ backgroundColor: routeColor, borderRadius: '50%', padding: '10px' }}
            >
              <FaCaretLeft
                className={`custom-icon2 ${currentTripIndex === 0 ? 'disabled-button' : ''}`}
                style={{ backgroundColor: routeColor, borderRadius: '50%', fontSize: '28px' }}
              />
            </button>
  
            <div 
              onClick={handleShowMap} 
              className="full-width-button" 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                position: 'relative', 
                paddingLeft: '20px', 
                borderRadius: '100px', 
                cursor: 'pointer', 
                height: '60px', 
                fontWeight: 'bold',
              }}
            >
              {/* Badge */}
              <div 
                style={{ 
                  position: 'absolute', 
                  top: '-20px', 
                  right: '0px', 
                  backgroundColor: routeColor, 
                  borderRadius: '25px', 
                  padding: '5px 20px', 
                  fontSize: '12px', 
                  textAlign: 'center', 
                  border: '1px solid #ffffff', 
                  color: isColorDark(routeColor) ? 'white' : 'black',
                }}
              >
                <b style={{ marginRight: '4px', color: isColorDark(routeColor) ? 'white' : 'black' }}>
                  {vehicleCount}
                </b>
                <FaBusSimple style={{ color: isColorDark(routeColor) ? 'white' : 'black' }} />
              </div>
  
              {/* GPS Icon */}
              <TbGpsFilled 
                style={{ position: 'absolute', left: '10px', fontSize: '30px', animation: 'fadeInOut_gps 6s infinite' }} 
              />
  
              {/* Button Text */}
              <span style={{ fontSize: '12px' }}>{buttonText}</span>
            </div>
  
            <button
              onClick={handleNextTrip}
              disabled={currentTripIndex === tripIds.length - 1}
              className={`custom-button ${currentTripIndex === tripIds.length - 1 ? 'disabled-button' : ''}`}
              style={{ backgroundColor: routeColor, borderRadius: '50%', padding: '10px' }}
            >
              <FaCaretRight
                className={`custom-icon2 ${currentTripIndex === tripIds.length - 1 ? 'disabled-button' : ''}`}
                style={{ backgroundColor: routeColor, borderRadius: '50%', fontSize: '28px' }}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  
    {/* Main Content Below Fixed Header */}
    {showAlert && (
      serviceEnded ? (
        <div style={{ marginTop: '200px', textAlign: 'center' }}>
        <GiNightSleep size={100} />  {/* Taille de l'icône */}
        <div style={{ marginTop: '10px', fontSize: '18px' }}> {/* Petit texte en dessous */}
          <span>Fin de service</span>
        </div>
      </div>
      ) : (
        <div style={{ marginTop: '200px', textAlign: 'center' }}>
        <GiNightSleep size={100} />  {/* Taille de l'icône */}
        <div style={{ marginTop: '10px', fontSize: '18px' }}> {/* Petit texte en dessous */}
          <span>Aucun service ce jour.</span>
        </div>
      </div>
      
      )
    )}
  
    {/* Schedule Container - displayed only when there is no alert */}
    {!showAlert && (
      <>
        {tripIds.length === 0 && (
          <div className="centered-text-home">
            <Spinner animation="border" role="status" className="custom-spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
  
        <div className="schedule-container" style={{ marginTop: '290px' }}>
          {tripIds.length > 0 && (
            <>
              {/* Schedule Items */}
              {currentTrip.map((schedule, index) => {
                const city = findClosestCity(parseFloat(schedule.stop_lat), parseFloat(schedule.stop_lon));
                const isLastItem = index === currentTrip.length - 1;
  
                const stop = stops.find(stop =>
                  stop.stop_lat === schedule.stop_lat && stop.stop_lon === schedule.stop_lon
                );
                const stopId = stop ? stop.stop_id : 'unknown';
                const tripId = schedule.trip_id;
  
                const realTimeStops = realTimeSchedules[tripId] || [];
                const realTimeStop = realTimeStops.find(rtStop => rtStop.stop_id === stopId && rtStop.stop_sequence === schedule.stop_sequence);
  
                const realTimeArrival = realTimeStop ? realTimeStop.time : null;
                const theoreticalArrival = formatArrivalTime(schedule.arrival_time);
                const displayTime = realTimeArrival || theoreticalArrival;
  
                let timeDifference = '';
                let adjustedTimeClass = '';
                let infoContainerClass = 'info-container';
                let dotClass = `dot ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`;
                let pastClass = '';
  
                if (realTimeStop && realTimeStop.schedule_relationship === 1) {
                  infoContainerClass += ' info-container-cancelled';
                  adjustedTimeClass = 'time-cancelled';
                  dotClass += ' cancelled';
                } else if (realTimeArrival && isToday(selectedDate)) {
                  const [realHours, realMinutes] = realTimeArrival.split(':').map(Number);
                  const [theoHours, theoMinutes] = theoreticalArrival.split(':').map(Number);
                  const realTimeInMinutes = realHours * 60 + realMinutes;
                  const theoreticalTimeInMinutes = theoHours * 60 + theoMinutes;
                  const diffInMinutes = realTimeInMinutes - theoreticalTimeInMinutes;
  
                  if (diffInMinutes > 0) {
                    timeDifference = `Retardé de ${formatTimeDifference(diffInMinutes)}`;
                    adjustedTimeClass = 'time-late';
                  } else if (diffInMinutes < 0) {
                    timeDifference = `En avance de ${formatTimeDifference(diffInMinutes)}`;
                    adjustedTimeClass = 'time-early';
                  } else {
                    timeDifference = "À l'heure";
                    adjustedTimeClass = 'time-on-time';
                  }
                }
  
                const now = new Date();
                const [scheduledHours, scheduledMinutes] = displayTime.split(':').map(Number);
                const scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), scheduledHours, scheduledMinutes);
                const isPast = isToday(selectedDate) && now > scheduledTime && (now.getHours() !== scheduledHours || now.getMinutes() !== scheduledMinutes);
                const isCurrent = isToday(selectedDate) && now.getHours() === scheduledHours && now.getMinutes() === scheduledMinutes;
                pastClass = isPast ? 'past' : '';
                const currentClass = isCurrent ? 'current' : '';
  
                const dotInnerColor = isColorDark(routeColor) ? 'white' : 'white';
  
                return (
                  <div key={`${schedule.trip_id}-${index}`} className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}>
                    <div className={`dot-container ${pastClass}`} style={{ '--route-color': routeColor }}>
                      <div
                        className={`${dotClass} ${pastClass} ${currentClass}`}
                        style={{
                          backgroundColor: (index === 0 || isLastItem) ? dotInnerColor : (isPast ? '#d1d1d1' : routeColor),
                          border: (index === 0 || isLastItem) ? `6px solid ${isPast ? '#d1d1d1' : routeColor}` : 'none',
                        }}
                      ></div>
                    </div>
  
                    <div className={`${infoContainerClass} ${pastClass}`} style={{ position: 'relative', cursor: 'pointer' }} onClick={() => navigate(`/network/${networkId}/stop/${stopId}`)}>
                      {realTimeArrival && isToday(selectedDate) && !isPast && (
                        <span style={{ position: 'absolute', right: '15px', top: '15%' }}>
                          <svg width="1em" height="1em" viewBox="0 0 20 20" style={{ transform: 'rotate(90deg)' }}>
                            <g fill="#FBAC2A">
                              <path d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685" style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}></path>
                              <path d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z" style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}></path>
                            </g>
                          </svg>
                        </span>
                      )}
                      <div className="stop">{schedule.stop_name}</div>
                      <div className="stop-city">{city}</div>
                      <div className="time-container">
                        {realTimeArrival && isToday(selectedDate) && realTimeArrival !== theoreticalArrival && (
                          <div className="time-strikethrough">{theoreticalArrival}</div>
                        )}
                        {timeDifference && (
                          <div className={`time-difference ${adjustedTimeClass}`}>{timeDifference}</div>
                        )}
                      </div>
                      <div className={`time ${adjustedTimeClass}`}>{displayTime}</div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </>
    )}
  
    {showMap && (
      <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', zIndex: 9998, background: 'white' }}>
        <div
          onClick={handleCloseMap}
          style={{
            position: 'absolute',
            top: '20px',
            right: '35px',
            zIndex: 10000,
            color: 'white',
            fontSize: '2rem',
            cursor: 'pointer',
            backgroundColor: '#242424',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
  
        <MapContainer
            style={{ height: "100%", width: "100%" }}
            whenCreated={mapInstance => { mapRef.current = mapInstance }}
          >
        <TileLayer
  url={isDay
    ? "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2NsZW1lbnQ2MCIsImEiOiJjbDEyOXRlaHEyZzkyM2JrYjJnN3ZrY2JrIn0.JrVxUKnkgR1vx8FWsNp9tQ" // Mode Jour
    : "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2NsZW1lbnQ2MCIsImEiOiJjbDEyOXRlaHEyZzkyM2JrYjJnN3ZrY2JrIn0.JrVxUKnkgR1vx8FWsNp9tQ" // Mode Nuit
  }
  attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
/>




          <MapBoundsSetter />
          {shapePoints.length > 0 && (
            <Polyline positions={shapePoints} color={routeColor} weight={10} />
          )}
          {stops.map((stop, index) => (
            <Marker
              key={index}
              position={[stop.stop_lat, stop.stop_lon]}
              icon={L.divIcon({
                className: 'custom-icon',
                html: `<div style="background-color: white; border-radius: 50%; width: 18px; height: 18px; border: 2px solid ${routeColor}; z-index: 500;"></div>`
              })}
              zIndexOffset={-1000}
            >
              <Popup>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '24px', textTransform: 'uppercase' }}>
                    {stop.stop_name}
                  </div>
                  <div style={{ color: 'gray', fontSize: '14px', marginTop: '8px' }}>
                    {findClosestCity(stop.stop_lat, stop.stop_lon)}
                  </div>
                  <div style={{ marginTop: '16px' }}>
                    <a
                      href={`/network/${networkId}/stop/${stop.stop_id}`}
                      style={{
                        display: 'inline-block',
                        padding: '10px 20px',
                        backgroundColor: '#0A78A4',
                        color: '#fff',
                        borderRadius: '50px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                    >
                      <FaClock /> Voir les prochains départs
                    </a>
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
          {realTimeVehicles.map((vehicle, index) => (
            <Marker
              key={index}
              position={[vehicle.position.latitude, vehicle.position.longitude]}
              icon={createVehicleIcon(routeColor, 'white', vehicle.bearing, vehicle.opacity)}
              zIndexOffset={1000}
            >
              <Popup offset={[0, -20]}>
                {renderVehiclePopup(vehicle)}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        <div style={{
          position: 'fixed',
          bottom: '10px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'auto',
          textAlign: 'center',
          fontSize: '14px',
          padding: '5px 20px',
          color: '#3a3a3a',
          zIndex: 10000,
        }}>
          Trajet n°{currentTripId}
        </div>
      </div>
    )}
  </Container>
  
  
  );
};

export default Schedule;
