import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, FormControl, Modal, ListGroup, Spinner, InputGroup, Carousel, Alert } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { GiBusStop } from "react-icons/gi";
import './App.css';
import { Helmet } from 'react-helmet-async';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { SiSlashdot } from "react-icons/si";
import { FaBusSimple } from "react-icons/fa6";

function BusLines() {
  const { networkId } = useParams();
  const navigate = useNavigate();
  const [lines, setLines] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedLine, setSelectedLine] = useState(null);
  const [directions, setDirections] = useState([]);
  const [stops, setStops] = useState([]);
  const [searchStopTerm, setSearchStopTerm] = useState('');
  const [loadingStops, setLoadingStops] = useState(false);
  const [articles, setArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const lineRefs = useRef([]); // Références pour chaque ligne

  useEffect(() => {
    axios.get(`/getBusLines.php?network_id=${networkId}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setLines(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching bus lines:', error);
      });
  }, [networkId]);

  useEffect(() => {
    if (searchStopTerm.length > 0) {
      setLoadingStops(true);
      axios.get(`/getBusStops.php?network_id=${networkId}&query=${searchStopTerm}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            const decodedStops = response.data.map(stop => ({
              ...stop,
              stop_name: decodeURIComponent(stop.stop_name)
            }));
            setStops(decodedStops);
          } else {
            setStops([]);
          }
        })
        .catch(error => {
          console.error('Error fetching bus stops:', error);
          setStops([]);
        })
        .finally(() => {
          setLoadingStops(false);
        });
    } else {
      setStops([]);
      setLoadingStops(false);
    }
  }, [searchStopTerm, networkId]);

  useEffect(() => {
    axios.get(`/getBusLines.php?network_id=${networkId}&get_articles=true`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setArticles(response.data);
        } else {
          setArticles([]);
        }
      })
      .catch(error => {
        console.error('Error fetching articles:', error);
        setArticles([]);
      })
      .finally(() => {
        setLoadingArticles(false);
      });
  }, [networkId]);

  const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };

  const filteredLines = lines.filter(line =>
    normalizeString(line.route_short_name).includes(normalizeString(searchTerm)) ||
    normalizeString(line.route_long_name).includes(normalizeString(searchTerm))
  );

  const filteredStops = stops.filter(stop =>
    normalizeString(stop.stop_name).includes(normalizeString(searchStopTerm))
  );

  const handleShowModal = (line) => {
    setSelectedLine(line);
    axios.get(`/getBusLines.php?route_id=${line.route_id}&network_id=${networkId}`)
      .then(response => {
        setDirections(response.data);
        setShowModal(true);
      })
      .catch(error => {
        console.error('Error fetching directions:', error);
      });
  };

  const handleSelectDirection = (direction_id) => {
    navigate(`/schedule/${networkId}/${selectedLine.route_id}/${direction_id}`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLine(null);
    setDirections([]);
  };

  const getTextColor = (backgroundColor) => {
    if (!backgroundColor || backgroundColor.length !== 6) return 'black';

    const r = parseInt(backgroundColor.substring(0, 2), 16);
    const g = parseInt(backgroundColor.substring(2, 4), 16);
    const b = parseInt(backgroundColor.substring(4, 6), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 150 ? 'black' : 'white';
  };

  // Intersection Observer avec délai pour afficher une ligne à la fois
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            // Appliquer l'animation slide-up pour toutes les lignes
            setTimeout(() => {
              entry.target.classList.add('slide-up');
  
              // Si la ligne est désactivée, appliquer disabled-line après l'animation (par exemple après 500ms)
              if (entry.target.classList.contains('disabled-line')) {
                setTimeout(() => {
                  entry.target.classList.add('disabled-line');
                }, 500); // Appliquer disabled-line après l'animation
              }
            }, index * 100); // Délai de 100ms entre chaque ligne
          }
        });
      },
      { threshold: 0.1 }
    );
  
    lineRefs.current.forEach((line) => {
      if (line) {
        observer.observe(line);
      }
    });
  
    return () => {
      lineRefs.current.forEach((line) => {
        if (line) {
          observer.unobserve(line);
        }
      });
    };
  }, [filteredLines]);
  
  

  return (
    <Container className="my-1 d-flex align-items-center justify-content-center">
      <Helmet>
        <title>Bus Connect - {networkId}</title>
      </Helmet>
      <div className="w-100">
        <img src={`/logo/${networkId}.png`} alt={`${networkId} Logo`} className="responsive-logo mx-auto d-block" style={{ maxHeight: '100px'}}/>
        
        <Form className="mb-4 text-center">
          <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4', maxWidth: '400px', minHeight: '50px', margin: '0 auto' }}>
            <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
              <GiBusStop />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Rechercher votre arrêt de bus..."
              onChange={e => setSearchStopTerm(e.target.value)}
              style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }}
            />
          </InputGroup>
          {loadingStops && (
            <div className="text-center mt-2">
              <Spinner animation="border" role="status" className="d-block mx-auto custom-spinner">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {Array.isArray(filteredStops) && filteredStops.length > 0 && !loadingStops && (
            <ListGroup className="mt-2 list-group-arrets">
              {filteredStops.map(stop => {
                const uniqueRoutesAndColors = stop.routesAndColors.filter(
                  (value, index, self) =>
                    index === self.findIndex((t) => t.route === value.route && t.color === value.color)
                );
                return (
                  <ListGroup.Item
                    key={stop.stop_id}
                    className="stop-item"
                    onClick={() => navigate(`/network/${networkId}/stop/${stop.stop_id}`)}
                  >
                    <div className="d-flex flex-column align-items-start">
                      <strong>{stop.stop_name}</strong>
                      <div className="badge-container">
                        {uniqueRoutesAndColors.map(({ route, color }) => (
                          <span
                            key={route}
                            style={{
                              textTransform: 'uppercase',
                              backgroundColor: `#${color}`,
                              color: getTextColor(color),
                              fontWeight: 'bold',
                              borderRadius: '5px',
                              padding: '2px 5px',
                              margin: '2px',
                              display: 'inline-block'
                            }}
                          >
                            {route}
                          </span>
                        ))}
                      </div>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          )}
        </Form>

        <Form className="mb-4 text-center">
          <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4', maxWidth: '400px', minHeight: '50px', margin: '0 auto' }}>
            <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
              <FaSearch />
            </InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Rechercher votre ligne de bus..."
              onChange={e => setSearchTerm(e.target.value)}
              style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }}
            />
          </InputGroup>
        </Form>

        <Row className="justify-content-center">
          <Col md="auto">
            <ListGroup>
            {filteredLines.map((line, index) => {
  const isDisabled = line.etat === 0;
  return (
    <div
      key={line.route_id}
      ref={(el) => (lineRefs.current[index] = el)} // Référence à chaque ligne
      className={`line-container ${isDisabled ? 'disabled-line' : ''}`} // Appliquer disabled-line si désactivé
      onClick={!isDisabled ? () => handleShowModal(line) : null} // Pas de clic si désactivé
    >
      <div
        className="line-badge"
        style={{
          backgroundColor: `#${line.route_color}`,
          color: getTextColor(line.route_color),
          boxShadow: `0px 2px 2px -3px #${line.route_color}`,
        }}
      >
   {/* Vérifie si route_type est égal à 715, sinon affiche route_short_name */}
{line.route_type === 715 ? (
  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <span style={{ marginRight: '10px' }}>TAD</span> {/* Augmenter la marge à 10px */}
    <FaBusSimple />
  </span>
) : (
  line.route_short_name
)}


      </div>
      <div className="line-text-line">
        <span className="route-long-name">{line.route_long_name}</span>
      </div>
      <IoIosArrowForward size={24} style={{ color: '#787878' }} />
    </div>
  );
})}

            </ListGroup>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <h2 style={{ color: '#0a78a4', textTransform: 'uppercase', marginBottom: '20px' }}>
              <SiSlashdot style={{ marginRight: '10px', fontSize: '14px'}} />
              Actualités
            </h2>

            {loadingArticles ? (
              <Spinner
                animation="border"
                role="status"
                className="d-block mx-auto custom-spinner"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : articles.length === 0 ? (
              <Alert variant="danger" className="text-center">
                Aucune actualité sur ce réseau
              </Alert>
            ) : (
              <Carousel
                indicators={false}
                interval={5000}
                controls={false}
                style={{ maxWidth: '100%', height: 'auto' }}
              >
                {articles.map((article) => (
                  <Carousel.Item
                    key={article.id}
                    style={{ position: 'relative', cursor: 'pointer' }}
                    onClick={() => navigate(`/article_detail?id=${article.id}`)}
                  >
                    <div
                      style={{
                        position: 'relative',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <img
                        className="d-block w-100"
                        src={article.image_url}
                        alt={article.title}
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          maxHeight: '500px',
                          borderRadius: '5px',
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          borderRadius: '5px',
                        }}
                      ></div>
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          left: 0,
                        }}
                      >
                        <h5
                          style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            padding: '5px 10px',
                            color: '#fff',
                            fontSize: '1.2rem',
                            margin: 0,
                          }}
                        >
                          {article.title}
                        </h5>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '20px',
                          right: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px 15px',
                          borderRadius: '30px',
                          backgroundColor: '#0a78a4',
                          color: 'white',
                          fontSize: '0.9rem',
                          textDecoration: 'none',
                          transition: 'none',
                          border: '2px solid white',
                        }}
                      >
                        En savoir plus
                        <IoIosArrowDroprightCircle size={20} style={{ marginLeft: '5px' }} />
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedLine ? selectedLine.route_long_name : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.entries(directions).map(([direction_id, terminus]) => (
              <div
                key={direction_id}
                style={{
                  backgroundColor: `#${selectedLine.route_color}`,
                  borderColor: `#${selectedLine.route_color}`,
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  margin: '8px',
                  userSelect: 'none',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: getTextColor(selectedLine.route_color),
                }}
                className="m-2"
                onClick={() => handleSelectDirection(direction_id)}
              >
                {terminus}
              </div>
            ))}
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
}

export default BusLines;
