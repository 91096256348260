import React, { useState, useEffect } from 'react';
import './reseau.css'; // Assure-toi que les styles pour l'accordéon sont également inclus dans ce fichier CSS

const Axo = () => {
  // Déclare un état pour gérer l'ouverture/fermeture des panneaux
  const [isOpen, setIsOpen] = useState({
    guide: false,
    exploitant: false,
  });

  // Met à jour le titre de la page
  useEffect(() => {
    document.title = "AXO - Agglo. Creil Sud Oise";
  }, []);

  // Fonction pour gérer l'ouverture/fermeture des panneaux
  const toggleAccordion = (section) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
    <div className="reseau-container">
        <section className="reseau-intro">
          <h1>AXO - Agglo. Creil Sud Oise</h1>
          <img
            src="https://www.oise-mobilite.fr/sites/default/files/styles/editoriale_image_droite_gauche/public/2023-06/img-20210507-111811.jpg" // Mets ici le chemin correct vers ton image locale
            alt="Bus AXO"
            className="reseau-image"
          />
          <p>
            Grâce à ses 40 véhicules (dont une majeure partie est accessible aux personnes à mobilité réduite) et près de 80 conducteurs, le réseau AXO assure vos déplacements sur les communes de Cramoisy, Creil, Maysel, Montataire, Nogent-sur-Oise, Rousseloy, Saint-Leu-d'Esserent, Saint-Maximin, Saint-Vaast-lès-Mello, Thiverny et Villers-Saint-Paul.
          </p>
          <p>
            Dans une démarche de protection de l'environnement, deux bus hybrides ont été acquis par l'ACSO et 80% des véhicules sont dotés d'une motorisation « Euro IV, V ou VI », limitant les rejets polluants.
          </p>
          <p>
            Chaque année, près de 3 100 000 voyages sont réalisés sur le réseau, soit près de 10 500 voyages par jour.
          </p>
          <h2>Le réseau AXO, c'est :</h2>
          <ul>
            <li>8 lignes Régulières : A, B, C1, C2, D, E, F, EXPRESS ALATA</li>
            <li>4 services de Transport à la Demande AXO+ (retrouvez les Modalités d'utilisation)</li>
            <li>6 lignes Scolaires : S1, S2, S3, S5, S6, S7</li>
          </ul>
          <p>
            Circulant du matin au soir du lundi au dimanche (selon les lignes), le réseau AXO dessert bien entendu la gare de Creil, mais pas seulement : zone d'activités, établissements scolaires, secondaires et universitaires, pôles administratifs ou de santé, lieux de loisirs, centres commerciaux... sont accessibles facilement et rapidement grâce aux lignes urbaines.
          </p>
          <p>
            Avec AXO, Bougez dans l'Agglo !
          </p>
        </section>

        {/* Ajout de l'accordéon ici */}
        <section className="reseau-accordion">
          <div className="accordion-item">
            <button onClick={() => toggleAccordion('guide')}>
              Guide et plan
            </button>
            {isOpen.guide && (
            <div className="accordion-content">
              <p>
              Retrouvez plus d'informations dès maintenant sur votre réseau en téléchargeant le guide :
          
              </p>
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2023-10/axo-guide-bus-sept-2023.pdf" download className="download-button">
                Guide AXO
                </a>
              </div>

              <p>
              Vous pouvez consulter le plan du réseau AXO en cliquant sur l'image :
             
          
              </p>
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2023-08/1000x850_plan-du-reseau-axo.pdf" download className="download-button">
                Plan du réseau AXO
                </a>
              </div>
            </div>
            )}
          </div>

          <div className="accordion-item">
            <button onClick={() => toggleAccordion('exploitant')}>
              Exploitant
            </button>
            {isOpen.exploitant && (
              <div className="accordion-content">
                <h4>RATP DEVELOPPEMENT</h4>
                <p>
                L’Agglomération Creil Sud Oise a confié à la société RATP DEVELOPPEMENT l’exploitation du réseau AXO pour une durée de 7 ans conformément aux termes de la convention de délégation de service public en vigueur au 1er septembre 2019.
                Le Groupe RATP propose à ses usagers en cas de réclamation, de litige, l’intervention gratuite d’une médiatrice. Pour toutes informations cliquez sur le lien ci-contre ratp.fr/mediateur/
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Axo;
