import React, { useEffect, useState } from 'react';
import protobuf from 'protobufjs';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Définir une icône personnalisée pour les véhicules
const vehicleIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const GtfsRtData = () => {
  const [vehiclePositions, setVehiclePositions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Charger le fichier .proto pour décoder les données GTFS-RT
        const root = await protobuf.load('src/proto/gtfs-realtime.proto'); // Assure-toi que ce chemin est correct

        // Obtenir le type GTFS-RT (FeedMessage)
        const FeedMessage = root.lookupType('transit_realtime.FeedMessage');

        // Récupérer les données GTFS-RT en Protobuf pour la position des véhicules
        const response = await fetch(
          'https://proxy.transport.data.gouv.fr/resource/ametis-amiens-gtfs-rt-vehicle-position'
        );
        
        if (!response.ok) {
          throw new Error(`Erreur: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();
        console.log("Taille du buffer : ", arrayBuffer.byteLength);

        // Décoder les données en Protobuf
        const message = FeedMessage.decode(new Uint8Array(arrayBuffer));
        console.log("Données décodées : ", message);

        // Transformer les données en un objet JS
        const object = FeedMessage.toObject(message, {
          enums: String, // convertir les énumérations en chaînes
          longs: String, // convertir les longs en chaînes (si nécessaire)
        });

        // Mettre à jour l'état avec les positions des véhicules
        setVehiclePositions(object.entity);

        // Afficher les données pour vérifier et diagnostiquer
        object.entity.forEach((entity, index) => {
          if (entity.vehicle) {
            console.log(`Vehicle ${index} data:`, entity.vehicle);
            if (entity.vehicle.trip) {
              console.log(`Trip details for Vehicle ${index}:`, entity.vehicle.trip);
            } else {
              console.log(`No trip details for Vehicle ${index}`);
            }
          }
        });
      } catch (error) {
        console.error('Erreur lors de la récupération ou du décodage des données GTFS-RT :', error);
      }
    };

    // Appel initial des données
    fetchData();

    // Mise à jour automatique toutes les 15 secondes
    const intervalId = setInterval(fetchData, 7000);

    // Nettoyer l'intervalle quand le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <h1>Positions des véhicules - Ametis Amiens</h1>
      <MapContainer center={[49.895, 2.302]} zoom={13} style={{ height: '500px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {vehiclePositions.length > 0 && vehiclePositions.map((entity) => (
          entity.vehicle && (
            <Marker
              key={entity.id}
              position={[entity.vehicle.position.latitude, entity.vehicle.position.longitude]}
              icon={vehicleIcon}
            >
              <Popup>
                <p>Vehicle ID: {entity.vehicle.vehicle.id}</p>
                <p>Route ID: {entity.vehicle.trip ? entity.vehicle.trip.routeId : 'N/A (Pas de données de trajet)'}</p>
                <p>Direction: {entity.vehicle.trip ? (entity.vehicle.trip.directionId === 1 ? 'Outbound' : 'Inbound') : 'N/A'}</p>
                <p>Speed: {entity.vehicle.position.speed ? `${entity.vehicle.position.speed} m/s` : 'N/A'}</p>
                <p>Bearing: {entity.vehicle.position.bearing ? `${entity.vehicle.position.bearing}°` : 'N/A'}</p>
              </Popup>
            </Marker>
          )
        ))}
      </MapContainer>
    </div>
  );
};

export default GtfsRtData;
