import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Spinner, Alert, Button, Form } from 'react-bootstrap';
import './ArticleDetail.css'; // Assurez-vous d'importer un fichier CSS personnalisé
import { RiUserShared2Fill } from "react-icons/ri";
import { FaComments } from "react-icons/fa";
import { FaCommentMedical } from "react-icons/fa6";
import { Helmet } from 'react-helmet-async';


const ArticleDetail = () => {
    const location = useLocation();
    const [article, setArticle] = useState(null);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const articleId = searchParams.get('id');

                const articleResponse = await axios.get(`/getArticleDetail.php?id=${articleId}`);
                const commentsResponse = await axios.get(`/getComments.php?article_id=${articleId}`);

                if (articleResponse.data) {
                    setArticle(articleResponse.data);

                    // Assurez-vous que la réponse des commentaires est un tableau
                    if (Array.isArray(commentsResponse.data)) {
                        // Trier les commentaires par date, les plus récents en premier
                        const sortedComments = commentsResponse.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                        setComments(sortedComments);
                    } else {
                        setComments([]);  // Si ce n'est pas un tableau, définissez un tableau vide
                    }
                } else {
                    setError('Article not found');
                }
            } catch (error) {
                setError('Error fetching article details');
                console.error('Error fetching article details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const articleId = new URLSearchParams(location.search).get('id');

        const formData = new FormData();
        formData.append('article_id', articleId);
        formData.append('name', name);
        formData.append('comment', comment);

        try {
            const response = await axios.post('/addComment.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                // Ajouter le nouveau commentaire en haut de la liste
                setComments([{ name, comment, created_at: new Date().toISOString() }, ...comments]);
                setName('');
                setComment('');
            } else {
                console.error('Error adding comment:', response.data.error);
            }
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    if (loading) {
        return (
            <Container className="article-detail-container mt-4">
                <Row className="justify-content-center">
                    <Spinner animation="border" />
                </Row>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="article-detail-container mt-4">
                <Row>
                    <Col>
                        <Alert variant="danger">{error}</Alert>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="article-detail-container">
            <Helmet>
      <title>Bus Connect - {article?.title}</title>
    </Helmet>
            <Button 
                variant="primary" 
                className="back-button mb-4" 
                onClick={() => window.history.back()}
            >
                Revenir en arrière
            </Button>
            <div className="header-banner">
            <h2>{article?.title}</h2>
            </div>
            <Row>
                <Col md={12} className="text-center">
                    <img 
                        src={article.image_url} 
                        alt={article.title} 
                        className="img-fluid mb-4" 
                        style={{ maxHeight: '400px', objectFit: 'cover', width: '100%' }}
                    />
                    <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }} />
                </Col>
            </Row>
            
            <hr />
            <h3><FaCommentMedical /> Votre commentaire :</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez votre nom" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        required 
                    />
                </Form.Group>

                <Form.Group controlId="formComment">
                    <Form.Label>Commentaire</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Entrez votre commentaire" 
                        value={comment} 
                        onChange={(e) => setComment(e.target.value)} 
                        required 
                    />
                </Form.Group>

                <Button 
                    variant="primary" 
                    className="back-button mt-3" 
                    type="submit"
                >
                    Envoyer
                </Button>
            </Form>
            <hr />
            <h3><FaComments /> Commentaires :</h3>
            {Array.isArray(comments) && comments.length > 0 ? (
                comments.map((comment, index) => {
                    const commentDate = new Date(comment.created_at);
                    const formattedDate = commentDate.toLocaleDateString('fr-FR', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    });
                    const formattedTime = commentDate.toLocaleTimeString('fr-FR', {
                        hour: '2-digit',
                        minute: '2-digit',
                    });

                    return (
                        <div key={index} className="comment">
                            <strong><RiUserShared2Fill />    {comment.name}</strong> - {`le ${formattedDate} à `}
                            <span style={{ fontSize: '1.1rem' }}>{formattedTime}</span>
                            <p>{comment.comment}</p>
                        </div>
                    );
                })
            ) : (
                <p>Aucun commentaire pour cet article.</p>
            )}

          
        </Container>
    );
};

export default ArticleDetail;
