import React, { useState } from 'react';
import axios from 'axios';

const Itineraire = () => {
  const [searchA, setSearchA] = useState('');
  const [searchB, setSearchB] = useState('');
  const [suggestionsA, setSuggestionsA] = useState([]);
  const [suggestionsB, setSuggestionsB] = useState([]);
  const [itineraire, setItineraire] = useState(null);

  // Fonction pour récupérer les suggestions d'arrêts
  const fetchSuggestions = async (input, setter) => {
    if (input.length > 2) {
      try {
        const response = await axios.get(`suggestions.php?query=${input}`);
        setter(response.data); // Mise à jour des suggestions
      } catch (error) {
        console.error("Erreur lors de la récupération des suggestions :", error);
        setter([]); // En cas d'erreur, tableau vide
      }
    } else {
      setter([]); // Vide les suggestions si moins de 3 caractères
    }
  };

  return (
    <div>
      <h2>Rechercher un itinéraire</h2>
      <div>
        <input
          type="text"
          placeholder="Point de départ"
          value={searchA}
          onChange={(e) => {
            setSearchA(e.target.value);
            fetchSuggestions(e.target.value, setSuggestionsA); // Requête pour les suggestions du champ A
          }}
        />
        <ul>
          {suggestionsA.map((suggestion, index) => (
            <li key={index} onClick={() => setSearchA(suggestion.name)}>
              {suggestion.name}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <input
          type="text"
          placeholder="Destination"
          value={searchB}
          onChange={(e) => {
            setSearchB(e.target.value);
            fetchSuggestions(e.target.value, setSuggestionsB); // Requête pour les suggestions du champ B
          }}
        />
        <ul>
          {suggestionsB.map((suggestion, index) => (
            <li key={index} onClick={() => setSearchB(suggestion.name)}>
              {suggestion.name}
            </li>
          ))}
        </ul>
      </div>

      <button onClick={() => console.log('Calcul de l\'itinéraire demandé')}>Calculer l'itinéraire</button>

      {itineraire && (
        <div>
          <h3>Itinéraire</h3>
          <p>De {itineraire.from} à {itineraire.to}</p>
          <p>Durée : {itineraire.duration}</p>
          {itineraire.steps.map((step, index) => (
            <p key={index}>Prendre {step.line} à {step.departureTime}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Itineraire;
