import React, { useState, useEffect } from 'react';
import './reseau.css'; // Assure-toi que les styles pour l'accordéon sont également inclus dans ce fichier CSS

const Corolis = () => {
  // Déclare un état pour gérer l'ouverture/fermeture des panneaux
  const [isOpen, setIsOpen] = useState({
    guide: false,
    exploitant: false,
  });

  // Met à jour le titre de la page
  useEffect(() => {
    document.title = "Corolis - C.A. du Beauvaisis";
  }, []);

  // Fonction pour gérer l'ouverture/fermeture des panneaux
  const toggleAccordion = (section) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
   <div className="reseau-container">
        <section className="reseau-intro">
          <h1>Corolis - C.A. du Beauvaisis</h1>
    
       
          <p>
            Le réseau Corolis dessert l’ensemble des 53 communes de la Communauté d'agglomération du Beauvaisis.
          </p>
          <p>Il est construit autour de :</p>
          <ul>
            <li>2 lignes <strong>CHRONO C1</strong> et <strong>C2</strong> desservant les principaux quartiers d’habitation et lieux de vie, toutes les 15 à 20 minutes entre 05h15 et 22h,</li>
            <li>5 lignes principales de desserte des quartiers avec des fréquences de 30 à 60 minutes,</li>
            <li>4 services de TAD Corolis à la demande (zones Centre, Est, Nord et Sud),</li>
            <li>3 lignes interurbaines régulières <strong>511, 521</strong> et <strong>531</strong> au départ des pôles générateurs du territoire et à destination de Beauvais qui circulent du lundi au samedi,</li>
            <li>Un service <strong>CHRONOPRO</strong> pour une desserte adaptée des zones d’activités,</li>
            <li>Des services spécifiques pour un parfait maillage du territoire : navette <strong>GratuitBus</strong>, navette express <strong>Hôtels</strong>, navette <strong>plan d’eau du Canada</strong>, lignes scolaires et <strong>navette Parc Saint-Paul</strong>.</li>
          </ul>
        </section>

        {/* Ajout de l'accordéon ici */}
        <section className="reseau-accordion">
          <div className="accordion-item">
            <button onClick={() => toggleAccordion('guide')}>
              Guide et plan
            </button>
            {isOpen.guide && (
            <div className="accordion-content">
            
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2023-06/rao-corolis-general.pdf" download className="download-button">
                Plan Corolis 
               
                </a>
              </div>

           
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2023-06/plan-interurbain-lignes-regulieres-iu-mef-mis-a-jour-le-07-09-22-compressed.pdf" download className="download-button">
                Plan Corolis interurbain
               
            
                </a>
              </div>
            </div>
            )}
          </div>

          <div className="accordion-item">
            <button onClick={() => toggleAccordion('exploitant')}>
              Exploitant
            </button>
            {isOpen.exploitant && (
              <div className="accordion-content">
                <h4>Corolis - Exploitant</h4>
                <p>
                La Communauté d’Agglomération du Beauvaisis a confié à la société Transdev Beauvaisis Mobilités, filiale du groupe Transdev, l’exploitation du réseau Corolis pour une durée de 7 ans conformément aux termes de la concession de service public en vigueur au 1er septembre 2022.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Corolis;
