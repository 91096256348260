import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';

const CreateArticle = () => {
    const [title, setTitle] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [content, setContent] = useState('');
    const [networkId, setNetworkId] = useState('');
    const [networks, setNetworks] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // Récupérer les réseaux (agences) depuis le backend
        axios.get('/getNetworks.php')
            .then(response => {
                setNetworks(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des réseaux:', error);
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const articleData = {
            title: title,
            image_url: imageUrl,
            content: content,
            network_id: networkId
        };
    
        console.log(articleData); // Ajoute ceci pour vérifier les données avant l'envoi
    
        axios.post('/admin/createArticle.php', articleData)
            .then(response => {
                if (response.data.success) {
                    setSuccessMessage('Article créé avec succès !');
                    setTitle('');
                    setImageUrl('');
                    setContent('');
                    setNetworkId('');
                } else {
                    setSuccessMessage(`Erreur lors de la création de l'article: ${response.data.error}`);
                }
            })
            .catch(error => {
                console.error('Erreur lors de l\'envoi de l\'article:', error);
                setSuccessMessage('Erreur lors de l\'envoi de l\'article. Vérifiez la console pour plus de détails.');
            });
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    return (
        <Container className="mt-5">
            <h2>Créer un Nouvel Article</h2>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez le titre de l'article" 
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formImageUrl">
                    <Form.Label>URL de l'image</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Entrez l'URL de l'image" 
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formContent">
                    <Form.Label>Contenu</Form.Label>
                    <ReactQuill 
                        value={content}
                        onChange={setContent}
                        theme="snow"
                        modules={modules}
                        placeholder="Rédigez votre article ici..."
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNetwork">
                    <Form.Label>Réseau</Form.Label>
                    <Form.Select 
                        value={networkId} 
                        onChange={(e) => setNetworkId(e.target.value)} 
                        required
                    >
                        <option value="">Sélectionner un réseau</option>
                        {networks.map((network) => (
                            <option key={network.network_id} value={network.network_id}>
                                {network.agency_name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Créer l'article
                </Button>
            </Form>
        </Container>
    );
};

export default CreateArticle;
