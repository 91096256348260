import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table, Alert } from 'react-bootstrap';

const GestionTrafic = () => {
  const [networks, setNetworks] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [routeColor, setRouteColor] = useState('#000000');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState(''); // Ajout du champ title
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [manualDisruptions, setManualDisruptions] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [editId, setEditId] = useState(null);
  const [networkId, setNetworkId] = useState('');

  useEffect(() => {
    // Récupérer les réseaux de bus
    axios.get('/admin/getNetworks.php')
      .then(response => {
        setNetworks(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des réseaux:', error);
      });

    // Récupérer les perturbations manuelles existantes
    fetchManualDisruptions();

    // Initialiser les dates par défaut
    const now = new Date();
    const nowString = now.toISOString().slice(0, 16); // format YYYY-MM-DDTHH:mm
    setStartDate(nowString);

    const tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    const tomorrowString = tomorrow.toISOString().slice(0, 16);
    setEndDate(tomorrowString);
  }, []);

  const fetchManualDisruptions = () => {
    axios.get('/admin/getManualDisruptions.php')
      .then(response => {
        setManualDisruptions(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des perturbations manuelles:', error);
      });
  };

  const handleNetworkChange = (e) => {
    const selectedNetworkName = e.target.value;
    setSelectedNetwork(selectedNetworkName);

    // Trouver l'ID du réseau sélectionné
    const selectedNetworkObj = networks.find(network => network.agency_name === selectedNetworkName);
    setNetworkId(selectedNetworkObj ? selectedNetworkObj.network_id : '');

    setSelectedLine(''); // Réinitialiser la ligne sélectionnée
    setRouteColor('#000000'); // Réinitialiser la couleur de la route

    // Récupérer les lignes de bus pour le réseau sélectionné
    axios.get(`/admin/getLines.php?network_name=${encodeURIComponent(selectedNetworkName)}`)
      .then(response => {
        setLines(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des lignes:', error);
      });
  };

  const handleLineChange = (e) => {
    const selectedLine = e.target.value;
    setSelectedLine(selectedLine);

    // Récupérer la couleur de la ligne sélectionnée
    if (selectedNetwork && selectedLine) {
      axios.get(`/admin/getLineColor.php?network_name=${encodeURIComponent(selectedNetwork)}&route_short_name=${encodeURIComponent(selectedLine)}`)
        .then(response => {
          if (response.data.route_color) {
            const color = response.data.route_color.startsWith('#') ? response.data.route_color : `#${response.data.route_color}`;
            setRouteColor(color); // Mettre à jour la couleur en fonction de la ligne sélectionnée
          } else {
            setRouteColor('#000000'); // Définir une couleur par défaut si aucune couleur n'est trouvée
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération de la couleur de la ligne:', error);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedNetwork || !networkId || !selectedLine || !routeColor || !status || !message || !startDate || !endDate || !title) {
      setAlert({ type: 'danger', message: 'Tous les champs doivent être remplis.' });
      return;
    }

    const data = {
      network_name: selectedNetwork,
      network_id: networkId,
      route_short_name: selectedLine,
      route_color: routeColor,
      status,
      message,
      start_date: startDate,
      end_date: endDate,
      title // Ajout du titre ici
    };

    const url = editId ? '/admin/updateManualDisruption.php' : '/admin/addManualDisruption.php';
    if (editId) data.id = editId;

    axios.post(url, data)
      .then(response => {
        if (response.data.status === 'success') {
          setAlert({ type: 'success', message: editId ? 'Perturbation mise à jour avec succès.' : 'Perturbation ajoutée avec succès.' });
          fetchManualDisruptions(); // Rafraîchir la liste des perturbations manuelles
          resetForm();
        } else {
          setAlert({ type: 'danger', message: response.data.message });
        }
      })
      .catch(error => {
        setAlert({ type: 'danger', message: 'Erreur lors de l\'ajout de la perturbation.' });
        console.error('Erreur lors de l\'ajout de la perturbation:', error);
      });
  };

  const resetForm = () => {
    setSelectedNetwork('');
    setSelectedLine('');
    setRouteColor('#000000');
    setStatus('');
    setMessage('');
    setTitle('');
    const now = new Date();
    const nowString = now.toISOString().slice(0, 16); // format YYYY-MM-DDTHH:mm
    setStartDate(nowString);

    const tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    const tomorrowString = tomorrow.toISOString().slice(0, 16);
    setEndDate(tomorrowString);
    setEditId(null);
  };

  const handleEdit = (disruption) => {
    setSelectedNetwork(disruption.network_name);
    setSelectedLine(disruption.route_short_name);
    setRouteColor(disruption.route_color);
    setStatus(disruption.status);
    setMessage(disruption.message);
    setTitle(disruption.title); // Ajouter ceci pour éditer le titre
    setStartDate(disruption.start_date.replace(' ', 'T'));
    setEndDate(disruption.end_date.replace(' ', 'T'));
    setEditId(disruption.id);

    // Charger les lignes pour le réseau sélectionné
    axios.get(`/admin/getLines.php?network_name=${encodeURIComponent(disruption.network_name)}`)
      .then(response => {
        setLines(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des lignes:', error);
      });
  };

  const handleDelete = (id) => {
    if (!id) {
      console.error("Erreur : L'ID est manquant ou indéfini.");
      setAlert({ type: 'danger', message: "Erreur : Impossible de supprimer, l'ID est manquant." });
      return;
    }

    axios.post('/admin/deleteManualDisruption.php', { id: id })
      .then(response => {
        if (response.data.status === 'success') {
          setAlert({ type: 'success', message: 'Perturbation supprimée avec succès.' });
          fetchManualDisruptions(); // Rafraîchir la liste des perturbations manuelles après suppression
        } else {
          console.error("Erreur lors de la suppression : ", response.data.message);
          setAlert({ type: 'danger', message: response.data.message });
        }
      })
      .catch(error => {
        console.error("Erreur lors de la suppression de la perturbation : ", error);
        setAlert({ type: 'danger', message: 'Erreur lors de la suppression de la perturbation.' });
      });
  };

  return (
    <Container className="mt-4">
      <h2>Gestion des Perturbations Manuelles</h2>
      {alert.message && (
        <Alert variant={alert.type} onClose={() => setAlert({ type: '', message: '' })} dismissible>
          {alert.message}
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
      
        <Form.Group controlId="networkSelect">
          <Form.Label>Réseau</Form.Label>
          <Form.Control as="select" value={selectedNetwork} onChange={handleNetworkChange} required>
            <option value="">Sélectionnez un réseau</option>
            {networks.map((network, index) => (
              <option key={index} value={network.agency_name}>
                {network.agency_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        
        <Form.Group controlId="lineSelect">
          <Form.Label>Numéro de Ligne</Form.Label>
          <Form.Control
            as="select"
            value={selectedLine}
            onChange={handleLineChange}
            disabled={!selectedNetwork}
            required
          >
            <option value="">Sélectionnez une ligne</option>
            {lines.map((line, index) => (
              <option key={index} value={line.route_short_name}>
                {line.route_short_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="routeColor">
          <Form.Label>Couleur</Form.Label>
          <Form.Control
            type="color"
            value={routeColor}
            onChange={(e) => setRouteColor(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="title">
          <Form.Label>Titre</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="statusSelect">
          <Form.Label>Statut</Form.Label>
          <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)} required>
            <option value="">Sélectionnez un statut</option>
            <option value="Circulation difficile">Circulation difficile</option>
            <option value="Retards">Retards</option>
            <option value="Travaux">Travaux</option>
            <option value="Grève">Grève</option>
            <option value="Pannes mécaniques">Pannes mécaniques</option>
            <option value="Intempéries">Intempéries</option>
            <option value="Manifestation">Manifestation</option>
            <option value="Accident">Accident</option>
            <option value="Déviation">Déviation</option>
            <option value="Absence de conducteur">Absence de conducteur</option>
            <option value="Incivilités">Incivilités</option>
            <option value="Problème technique">Problème technique</option>
            <option value="Autre">Autre</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="startDate">
          <Form.Label>Date de Début</Form.Label>
          <Form.Control
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="endDate">
          <Form.Label>Date de Fin</Form.Label>
          <Form.Control
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          {editId ? 'Mettre à jour la perturbation' : 'Ajouter Perturbation'}
        </Button>
        {editId && (
          <Button variant="secondary" onClick={resetForm} className="mt-3 ml-2">
            Annuler la modification
          </Button>
        )}
      </Form>

      <h3 className="mt-5">Perturbations Manuelles Actuelles</h3>
      {manualDisruptions.length > 0 ? (
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Titre</th>
              <th>Réseau</th>
              <th>Numéro de Ligne</th>
              <th>Couleur</th>
              <th>Statut</th>
              <th>Message</th>
              <th>Date de Début</th>
              <th>Date de Fin</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {manualDisruptions.map((disruption, index) => (
              <tr key={index}>
                <td>{disruption.title}</td>
                <td>{disruption.network_name}</td>
                <td>{disruption.route_short_name}</td>
                <td>
                  <span
                    style={{
                      backgroundColor: disruption.route_color,
                      display: 'inline-block',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      color: disruption.route_color === '#000000' ? '#fff' : '#000',
                    }}
                  >
                    {disruption.route_color}
                  </span>
                </td>
                <td>{disruption.status}</td>
                <td>{disruption.message}</td>
                <td>{new Date(disruption.start_date).toLocaleString()}</td>
                <td>{new Date(disruption.end_date).toLocaleString()}</td>
                <td>
                  <Button variant="danger" onClick={() => handleDelete(disruption.id)}>
                    Supprimer
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Aucune perturbation manuelle trouvée.</p>
      )}
    </Container>
  );
};

export default GestionTrafic;
