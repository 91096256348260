import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Alert } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { GrInProgress, GrValidate } from 'react-icons/gr';
import { MdNumbers } from 'react-icons/md';
import './App.css'; // Assurez-vous que ce fichier contient les styles nécessaires pour les cartes
import { Helmet } from 'react-helmet-async';

const Incident = () => {
  const [incidents, setIncidents] = useState([]);

  useEffect(() => {
    axios.get('/getIncidents.php')
      .then(response => {
        console.log("Données reçues :", response.data); // Log des données reçues
        // Trier les incidents avant de les définir dans l'état
        const sortedIncidents = response.data.sort((a, b) => {
          if (a.etat === 2 && b.etat !== 2) return 1;
          if (a.etat !== 2 && b.etat === 2) return -1;
          return b.id - a.id; // Tri par ID décroissant
        });
        setIncidents(sortedIncidents);
      })
      .catch(error => console.error('Error fetching incidents:', error));
  }, []);

  // Fonction pour convertir l'état numérique en texte lisible
  const getEtatText = (etat) => {
    const etatInt = parseInt(etat, 10); // Conversion de l'état en entier
    console.log("Etat reçu :", etatInt); // Log pour vérifier la valeur de l'état
    switch (etatInt) {
      case 0:
        return 'Non résolu';
      case 1:
        return 'En cours de résolution';
      case 2:
        return 'Résolu';
      default:
        return 'Inconnu'; // Si l'état n'est pas reconnu, renvoie "Inconnu"
    }
  };

  // Fonction pour obtenir la classe de fond en fonction de l'état
  const getEtatBackgroundClass = (etat) => {
    const etatInt = parseInt(etat, 10); // Conversion de l'état en entier
    switch (etatInt) {
      case 0:
        return 'bg-etat-0'; // Rouge personnalisé pour non résolu
      case 1:
        return 'bg-etat-1'; // Orange personnalisé pour en cours
      case 2:
        return 'bg-etat-2'; // Vert personnalisé pour résolu
      default:
        return '';
    }
  };

  // Fonction pour obtenir l'icône en fonction de l'état
  const getEtatIcon = (etat) => {
    const etatInt = parseInt(etat, 10); // Conversion de l'état en entier
    switch (etatInt) {
      case 0:
        return <RxCross2 size={48} />; // Grande croix pour non résolu
      case 1:
        return <GrInProgress size={48} />; // Icône de progrès pour en cours
      case 2:
        return <GrValidate size={48} />; // Icône de validation pour résolu
      default:
        return null;
    }
  };

  // Fonction pour formater la date
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="container mt-4">
        <Helmet>
        <title>Bus Connect - Incident</title>
      </Helmet>
      <h1>Incidents</h1>
      {incidents.length === 0 ? (
        <Alert variant="success">
          Aucun incident trouvé. Tout semble fonctionner correctement !
        </Alert>
      ) : (
        incidents.map((incident) => (
          <Card key={incident.id} className="mb-4 incident-card">
            <Card.Header className={`text-white d-flex justify-content-between align-items-center ${getEtatBackgroundClass(incident.etat)}`}>
              <div className="incident-status-text" style={{ fontSize: '18px' }}>
                {getEtatText(incident.etat)}
              </div>
              <div className="incident-status-icon">
                {getEtatIcon(incident.etat)}
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Title className="d-flex justify-content-between align-items-center">
                <span>{incident.titre}</span>
                <div style={{ textAlign: 'right', fontSize: '12px', color: '#666' }}>
                  <div>{formatDate(incident.date)}</div>
                  <div>
                    <MdNumbers size={16} style={{ marginRight: '4px' }} />
                    ID : {incident.id}
                  </div>
                </div>
              </Card.Title>
              <Card.Text>
                {incident.content}
              </Card.Text>
              {incident.date_resolu && (
                <p className="incident-card-resolution-date" style={{ fontStyle: 'italic', color: '#555' }}>
                  <GrValidate size={16} style={{ marginRight: '4px' }} /> résolu le {formatDate(incident.date_resolu)}
                </p>
              )}
            </Card.Body>
          </Card>
        ))
      )}
    </div>
  );
};

export default Incident;
