import React, { useState, useEffect } from 'react';
import './reseau.css'; // Assure-toi que les styles pour l'accordéon sont également inclus dans ce fichier CSS

const Duc = () => {
  // Déclare un état pour gérer l'ouverture/fermeture des panneaux
  const [isOpen, setIsOpen] = useState({
    guide: false,
    exploitant: false,
  });

  // Met à jour le titre de la page
  useEffect(() => {
    document.title = "DUC - C.C. de l’Aire Cantilienne";
  }, []);

  // Fonction pour gérer l'ouverture/fermeture des panneaux
  const toggleAccordion = (section) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
    <div className="reseau-container">
        <section className="reseau-intro">
          <h1>DUC - C.C. de l’Aire Cantilienne</h1>
          <img
            src="https://www.oise-mobilite.fr/sites/default/files/styles/editoriale_image_droite_gauche/public/2023-06/vehicule-duc.jpg?itok=HOayF1Lo" // Mets ici le chemin correct vers ton image locale
            alt="Bus DUC"
            className="reseau-image"
          />
          <p>
            Pour vous déplacer au sein de la ville de Chantilly, la Communauté de communes de l'Aire Cantilienne (CCAC) propose un réseau de transport gratuit adapté aux besoins des quartiers et centré sur la gare, en connexion avec les trains.
          </p>
          <h2>Le DUC</h2>
          <p>
            Le <strong>DUC</strong> est en service de 6h00 à 20h45 en semaine et de 7h00 à 20h00 le samedi. Il ne fonctionne pas les jours fériés.
          </p>
          <p>
            Service de correspondance garantie : du lundi au vendredi, en cas de retard du train dans la limite de 20 minutes après l’horaire théorique d’arrivée du train, la correspondance avec les derniers bus du soir est assurée en gare de Chantilly-Gouvieux.
          </p>
          <p>
            Travail, études, loisirs, il permet un accès facile à la gare, au collège, au lycée et au centre-ville. Il permet également de relier les différents quartiers de la ville, et de rejoindre les lignes du réseau interurbain de l’Oise qui permettent des liaisons avec les communes avoisinantes. Il est possible également d'emprunter gratuitement la ligne 645 du Réseau Oise (Chantilly - Senlis) dans Chantilly intra-muros.
          </p>
          <h3>Nouveauté !</h3>
          <p>
            À partir du 2 septembre 2024, pour faciliter la mobilité sur le territoire, le réseau DUC évolue avec l’ajout d’un nouvel arrêt « Victor Hugo », permettant aux résidents du Bois Saint Denis d’accéder plus rapidement à l’entrée de la gare ou de rejoindre le pôle médical et ses commerces.
          </p>

          <h3>Nouveauté !</h3>
          <p>
          À partir du 2 septembre 2024, pour faciliter la mobilité sur le territoire, le réseau DUC évolue avec l’ajout d’un nouvel arrêt « Victor Hugo », permettant aux résidents du Bois Saint Denis d’accéder plus rapidement à l’arrière de la gare ou de rejoindre le  pôle médical et ses commerces. 
          </p>

          <p>
          Il existe également une Navette touristique en service le samedi, le dimanche et les jours fériés pour desservir les sites touristiques de la ville depuis la gare.
          </p>

          <h3>Nouveauté !</h3>
          <p>
          À partir du 1er septembre 2024, une course supplémentaire est ajoutée à 10h07 au départ de la gare.
          </p>

        </section>

        {/* Ajout de l'accordéon ici */}
        <section className="reseau-accordion">
          <div className="accordion-item">
            <button onClick={() => toggleAccordion('guide')}>
              Guide et plan
            </button>
            {isOpen.guide && (
            <div className="accordion-content">
            
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2024-09/livret-28p-duc-645-navette-touristique-septembre-2024-vf.pdf" download className="download-button">
                Guide horaires DUC
               
                </a>
              </div>

           
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2024-09/plan-de-reseau-duc-septembre-2024.pdf" download className="download-button">
                Plan DUC
            
                </a>
              </div>
            </div>
            )}
          </div>

          <div className="accordion-item">
            <button onClick={() => toggleAccordion('exploitant')}>
              Exploitant
            </button>
            {isOpen.exploitant && (
              <div className="accordion-content">
                <h4>Duc - Exploitant</h4>
                <p>
                L’exploitation du réseau urbain DUC a été confiée à la société Keolis Oise, filiale du groupe Keolis, pour une durée de quatre ans conformément aux termes du marché de transports publics en vigueur depuis le 1er septembre 2024.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Duc;
