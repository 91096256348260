import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, ListGroup, Form, Card } from 'react-bootstrap';
import './App.css';

const Arrets = () => {
  const [stops, setStops] = useState([]);
  const [filteredStops, setFilteredStops] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedStop, setSelectedStop] = useState(null);
  const [passages, setPassages] = useState([]);

  useEffect(() => {
    axios.get('/getArrets.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setStops(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch(error => console.error('Error fetching stops:', error));
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value.length >= 3) {
      const lowercasedValue = value.toLowerCase();
      const filtered = stops.filter(stop => {
        const stopName = stop.stop_name.toLowerCase();
        const agencyName = stop.agency_name.toLowerCase();
        return stopName.includes(lowercasedValue) || agencyName.includes(lowercasedValue);
      });

      const uniqueStops = [];
      const seen = new Set();

      filtered.forEach(stop => {
        const identifier = `${stop.stop_name}-${stop.agency_name}`;
        if (!seen.has(identifier)) {
          seen.add(identifier);
          uniqueStops.push(stop);
        }
      });

      setFilteredStops(uniqueStops);
    } else {
      setFilteredStops([]);
    }
  };

  const handleStopClick = (stop) => {
    setSelectedStop(stop);
    axios.get(`/getPassages.php?stop_id=${stop.stop_id}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setPassages(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch(error => console.error('Error fetching passages:', error));
  };

  return (
    <Container className="mt-4">
      <h2>Horaires par arrêt</h2>
      <Form.Group controlId="formSearch">
        <Form.Label>Rechercher un arrêt de bus</Form.Label>
        <Form.Control
          type="text"
          placeholder="Entrez le nom de l'arrêt ou du réseau"
          value={query}
          onChange={handleSearch}
        />
      </Form.Group>
      <ListGroup className="mt-4" style={{ border: '1px solid #000', borderRadius: '5px', overflow: 'hidden', margin: '0' }}>
        {Array.isArray(filteredStops) && filteredStops.map((stop, index) => (
          <div 
            key={stop.stop_id} 
            style={{ 
              textDecoration: 'none', 
              color: 'inherit',
              cursor: 'pointer',
              display: 'block'
            }}
            onClick={() => handleStopClick(stop)}
          >
            <ListGroup.Item 
              className="d-flex justify-content-between align-items-center" 
              style={{ 
                border: 'none', 
                borderBottom: index !== filteredStops.length - 1 ? '1px solid #000' : 'none', 
                padding: '10px 15px',
                backgroundColor: '#fff', // couleur de fond
                color: '#000' // couleur du texte
              }}
            >
              <div className="d-flex align-items-center">
                <span>{stop.stop_name} ({stop.agency_name})</span>
              </div>
            </ListGroup.Item>
          </div>
        ))}
      </ListGroup>

      {selectedStop && (
        <Card className="mt-4">
          <Card.Header>Prochains passages pour {selectedStop.stop_name} ({selectedStop.agency_name})</Card.Header>
          <Card.Body>
            <ListGroup>
              {Array.isArray(passages) && passages.map((passage, index) => (
                <ListGroup.Item key={index}>
                  {`Ligne ${passage.route_id} - Arrivée : ${passage.arrival_time} - Départ : ${passage.departure_time}`}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default Arrets;
