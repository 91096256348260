import React from 'react';
import { Helmet } from 'react-helmet-async';

const Mentions = () => {
  return (
    <div className="container mt-4">
        <Helmet>
      <title>Bus Connect - Mentions Légales</title>
    </Helmet>
      <div className="card">
        <div className="card-body">
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Bus Connect l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
          </p>
          <br />
          <h2>Édition de l'application</h2>
          <p>
            L'application « BUS CONNECT » est éditée par :<br />
            Clément WEIBEL, résidant Pont-Sainte-Maxence, 60700, de nationalité Française (France), né le 16/02/1998,
          </p>
          <br />
          <h2>Hébergement</h2>
          <p>
            Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).
          </p>
          <br />
          <h2>Directeur de publication</h2>
          <p>
            Le Directeur de la publication du Site est Clément WEIBEL.
          </p>
          <br />
          <h2>Open Data</h2>
          <p>
            Les données (horaires) et les informations sont fournies par <img src="image/oisemobi.svg" width="100" alt="Oise Mobi" /> (Syndicat mixte des transports collectifs de l’Oise). C'est un outil au service d’une meilleure organisation des transports collectifs.
          </p>
          <p>
            Un outil basé sur les données ouvertes de : <img src="image/oisemobi.svg" width="100" alt="Oise Mobi" />, géré par le Syndicat Mixte des Transports Collectifs de l’Oise (SMTCO). En utilisant cette application, vous acceptez les conditions énoncées dans les présentes mentions légales.
          </p>
          <p>
            Les données sont également utilisées par <img src="image/transport.55a2f6bfa7c462bde5524d8184436d79.svg" width="100" alt="Transport Data" /> <img src="image/logo-black.svg" width="100" alt="Transport Data" />.
          </p>
          <br />
          <h4>- Ouverture des Données Publiques (Open Data)</h4>
          <p>
            Le SMTCO s'est engagé dans la démarche d'ouverture des données publiques (open data) dans le secteur du transport. Toutes les données collectées pour ses systèmes d'information, y compris la description des services de transport public et les informations routières, sont rendues accessibles et utilisables par tous. Cette initiative vise à encourager les développeurs, chercheurs, acteurs socio-économiques et associatifs à exploiter ces données pour innover et créer de nouveaux services, applications mobiles pour les habitants, les entreprises et les visiteurs du département de l'Oise.
          </p>
          <br />
          <h4>- Objectifs de l'Open Data</h4>
          <p>
            Le SMTCO a adopté l'open data pour faciliter la mobilité des personnes, qu'elles soient résidentes ou visiteuses, se déplaçant quotidiennement dans l'Oise. Cette démarche vise également à renforcer la qualité de vie et la dynamique du territoire en encourageant l'innovation dans le domaine des transports publics.
          </p>
          <br />
          <h4>- Utilisation des Données par l'Application Bus Connect</h4>
          <p>
            L'application Bus Connect utilise les données ouvertes de <img src="image/oisemobi.svg" width="100" alt="Oise Mobi" /> pour faciliter les déplacements en transports en commun. 
            Elle offre la possibilité de visualiser la position des bus en temps réel. 
            Il est important de noter que la géolocalisation des bus est disponible uniquement lorsque le bus est en service. 
            Le conducteur déclenche sa course avec un numéro spécifique, ce qui affiche les données et positions des bus sur la carte. 
            Si le chauffeur est en coupure (pause), le véhicule ne sera pas géolocalisé, à moins que le conducteur n'ouvre sa course. 
            En utilisant l'application Bus Connect, vous acceptez les conditions d'utilisation des données en temps réel conformément aux directives énoncées dans les présentes mentions légales.
          </p>
          <br />
          <p>
            Aucune réclamation ne sera prise en compte dans l'application, en cas de problème (bus absent ou autre) merci de prendre contact avec <br /><img src="image/oisemobi.svg" width="100" alt="Oise Mobi" /> au <b>0970 150 150</b>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mentions;
