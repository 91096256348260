import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Accordion, Card, Row, Col, Form, ListGroup, Spinner, InputGroup } from 'react-bootstrap';
import { PiTrafficConeDuotone } from "react-icons/pi";
import { FaSearch } from "react-icons/fa"; // Import de l'icône de recherche et de la flèche
import { Helmet } from 'react-helmet-async';

const InfoTrafic = () => {
  const [trafficInfo, setTrafficInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLines, setFilteredLines] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [totalDisruptions, setTotalDisruptions] = useState(0);

  const fetchTrafficInfo = () => {
    axios.get('/getTrafficInfo.php')
      .then(response => {
        setTrafficInfo(response.data);
        setTotalDisruptions(
          Object.values(response.data).reduce((total, network) => 
            total + Object.values(network).reduce((sum, disruptions) => sum + disruptions.length, 0), 0)
        );
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching traffic information:', error);
        setError('Une erreur est survenue lors de la récupération des informations de trafic.');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTrafficInfo();
    const interval = setInterval(fetchTrafficInfo, 10000);
    return () => clearInterval(interval);
  }, []);

  const isDarkColor = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 128;
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      const results = [];
      Object.keys(trafficInfo).forEach((networkName) => {
        Object.keys(trafficInfo[networkName]).forEach((routeShortName) => {
          if (
            routeShortName.toLowerCase().includes(term.toLowerCase()) || 
            networkName.toLowerCase().includes(term.toLowerCase())
          ) {
            results.push({ routeShortName, networkName });
          }
        });
      });
      setFilteredLines(results);
    } else {
      setFilteredLines([]);
    }
  };

  const handleSuggestionClick = (routeShortName, networkName) => {
    setActiveKey(networkName);
    setSearchTerm(''); // Efface le terme de recherche
    setFilteredLines([]); // Efface les suggestions
    setTimeout(() => {
      document.getElementById(`route-${routeShortName}`).scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} à ${hours}:${minutes}`;
  };

  return (
    <Container className="mt-4">
        <Helmet>
      <title>Bus Connect - Infos Trafic</title>
    </Helmet>
      <div style={{ textAlign: 'center' }}>
        <div style={{ color: '#0a78a4', fontSize: '5em', marginBottom: '10px', marginTop: '-40px' }}>
          <PiTrafficConeDuotone />
        </div>
        <div style={{ marginBottom: '30px' }}>
          <h2 style={{ display: 'inline', marginRight: '10px' }}>
            Infos Trafic
          </h2>
          <span style={{ color: 'gray', fontSize: '0.8em' }}>
            ({totalDisruptions})
          </span>
        </div>
      </div>
      <Form.Group controlId="busLineSearch" className="mb-3">
        <InputGroup style={{ borderRadius: '20px', overflow: 'hidden', border: '2px solid #0a78a4' }}>
          <InputGroup.Text style={{ backgroundColor: 'white', border: 'none', padding: '0 15px' }}>
            <FaSearch />
          </InputGroup.Text>
          <Form.Control 
            type="text" 
            placeholder="Rechercher votre ligne de bus" 
            value={searchTerm}
            onChange={handleSearch}
            style={{ border: 'none', boxShadow: 'none', paddingLeft: 0 }} // Enlever les bordures et l'ombre, et ajuster le padding
          />
        
        </InputGroup>
        {filteredLines.length > 0 && (
          <ListGroup className="mt-2">
            {filteredLines.map((line, index) => (
              <ListGroup.Item 
                key={index} 
                action 
                onClick={() => handleSuggestionClick(line.routeShortName, line.networkName)}
              >
                {line.routeShortName} - {line.networkName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Form.Group>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div>
      ) : (
        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          {Object.keys(trafficInfo).sort().map((networkName, index) => (
            <Accordion.Item eventKey={networkName} key={index}>
              <Accordion.Header>{networkName} ({Object.keys(trafficInfo[networkName]).length})</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {Object.keys(trafficInfo[networkName]).map((routeShortName, idx) => {
                    const routeColor = trafficInfo[networkName][routeShortName][0]?.route_color || '#000';
                    const textColor = isDarkColor(routeColor) ? '#fff' : '#000';

                    return (
                      <Col key={idx} xs={12} md={6} className="mb-3">
                        <Card style={{ borderColor: routeColor, borderWidth: '2px', borderStyle: 'solid' }}>
                          <Card.Header 
                            id={`route-${routeShortName}`}
                            style={{
                              backgroundColor: routeColor, 
                              color: textColor, 
                              textAlign: 'center', 
                              fontWeight: 'bold', 
                              fontSize: '1.5rem',
                            }}>
                            {routeShortName}
                          </Card.Header>
                          <Card.Body>
                            {trafficInfo[networkName][routeShortName].map((disruption, disruptionIdx) => (
                              <div key={disruptionIdx} className="mb-2">
                                <h5>{disruption.status}</h5>
                                <p dangerouslySetInnerHTML={{ __html: disruption.message }}></p>
                                <p>Du: {formatDate(disruption.startDate)}</p>
                                <p>Au: {formatDate(disruption.endDate)}</p>
                                {disruptionIdx < trafficInfo[networkName][routeShortName].length - 1 && <hr />}
                              </div>
                            ))}
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </Container>
  );
};

export default InfoTrafic;
