import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Container, Badge, ListGroup } from 'react-bootstrap';

const LineTrafficDetails = () => {
  const [searchParams] = useSearchParams();
  const [lineInfo, setLineInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = searchParams.get('trafic');

  useEffect(() => {
    if (query) {
      console.log(`Query param received: ${query}`); // Log pour déboguer
      axios.get(`/getLineTrafficDetails.php?trafic=${query}`)
        .then(response => {
          setLineInfo(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching line traffic information:', error);
          setError('Une erreur est survenue lors de la récupération des informations de la ligne.');
          setLoading(false);
        });
    }
  }, [query]);

  return (
    <Container className="mt-4">
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div>
      ) : (
        <div>
          <h2>
            <Badge bg="primary" className="me-2">{lineInfo.routeShortName}</Badge>
            {lineInfo.routeName}
          </h2>
          <h4>{lineInfo.networkName}</h4>
          <ListGroup className="mt-4">
            {lineInfo.disruptions && lineInfo.disruptions.map((disruption, idx) => (
              <ListGroup.Item key={idx} className="d-flex align-items-center">
                <div style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  marginRight: '10px',
                }}></div>
                <div>
                  <strong>{disruption.title}</strong><br />
                  <span>Jusqu'au : {new Date(disruption.endDate).toLocaleString()}</span>
                  <p>{disruption.details}</p> {/* Ajout des détails */}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </Container>
  );
};

export default LineTrafficDetails;
