import React, { useState, useEffect } from 'react';
import './reseau.css'; // Assure-toi que les styles pour l'accordéon sont également inclus dans ce fichier CSS

const Tic = () => {
  // Déclare un état pour gérer l'ouverture/fermeture des panneaux
  const [isOpen, setIsOpen] = useState({
    guide: false,
    exploitant: false,
  });

  // Met à jour le titre de la page
  useEffect(() => {
    document.title = "TIC - A.R. de Compiègne";
  }, []);

  // Fonction pour gérer l'ouverture/fermeture des panneaux
  const toggleAccordion = (section) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
    <div className="reseau-container">
        <section className="reseau-intro">
          <h1>TIC - A.R. de Compiègne</h1>
          <img
            src="https://www.oise-mobilite.fr/sites/default/files/styles/editoriale_image_droite_gauche/public/2023-06/vhicule-tic-petit.png" // Mets ici le chemin correct vers ton image locale
            alt="Bus TIC"
            className="reseau-image"
          />
          <p>
            Les <strong>TIC</strong>, transports intercommunaux du Compiégnois, desservent gratuitement l’ensemble des communes de l’agglomération.
          </p>
          <p>
            6 lignes urbaines desservent la partie centrale de l’agglomération et 6 lignes périurbaines et 1 ligne Express (à vocation urbaine et périurbaine), ouvertes au grand public, complètent la desserte sur toutes les communes de l’agglomération.
          </p>
          <p>
            Attention, certaines courses des lignes non urbaines TIC n° <strong>101, 105, 107, 109</strong> et de l'ARC Express sont soumises à réservation auprès de l'agence Oise Mobilité, par téléphone au <span className="highlight-number">0970 150 150</span> ou par mail à <a href="mailto:reservation-tad@oise-mobilite.fr">reservation-tad@oise-mobilite.fr</a>.
          </p>
          <p>
            En plus de ces lignes régulières, il existe également un service de transport collectif à la demande <strong>AlloTIC</strong> permettant de compléter l’offre proposée par l’ARC.
          </p>
          <p>
            Le dimanche et les jours fériés, vous pouvez circuler dans la partie centrale de l’agglomération gratuitement grâce à deux lignes spécifiques sillonnant les communes de Compiègne, Margny et Venette. Ce service n’est pas assuré durant l’été ainsi que certains jours spécifiques comme le 1er mai, 25 décembre et 1er janvier.
          </p>
        </section>

        {/* Ajout de l'accordéon ici */}
        <section className="reseau-accordion">
          <div className="accordion-item">
            <button onClick={() => toggleAccordion('guide')}>
              Guide et plan
            </button>
            {isOpen.guide && (
            <div className="accordion-content">
            
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2024-08/arc_mob_plan_urbain_papier_v2024-05-13.pdf" download className="download-button">
               Plan  Lignes urbaines
                </a>
              </div>

           
              <div className="download-button-container">
                <a href="https://www.oise-mobilite.fr/sites/default/files/2024-08/arc_mob_plan_periurbain_papier_v2024-03.pdf" download className="download-button">
                Plan  Lignes péri urbaines
                </a>
              </div>
            </div>
            )}
          </div>

          <div className="accordion-item">
            <button onClick={() => toggleAccordion('exploitant')}>
              Exploitant
            </button>
            {isOpen.exploitant && (
              <div className="accordion-content">
                <h4>Tic - exploitant</h4>
                <p>
                L’Agglomération de la Région de Compiègne a confié à la société Acary, filiale du groupe Transdev, l’exploitation du réseau urbain TIC pour une durée de sept ans conformément aux termes du marché de transports publics en vigueur depuis le 15 juillet 2021.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Tic;
